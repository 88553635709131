import { useState } from "react";
import { SlidePaymentRequest } from "../outstanding/SlidePaymentRequest";
import { SuccessPopup } from "./SuccessPopup";
import { AlertPopup } from "../../../LayoutSide/components/common/alert-popup";
import { sendCall } from "../../../../../config/Utility";
import { useMyUser } from "src/app/hooks/useMyUser";
import { useSystemConfig } from "src/app/hooks/useSystemConfig";
import { Drawer } from "src/app/components/drawer";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { Button } from "src/app/components/button";

export function OutstandingFilters(props) {
  const config = useSystemConfig();
  const user = useMyUser();

  const [showAlertPopupFlag, setShowAlertPopupFlag] = useState(false);
  const [alertPopupMeta, setAlertPopupMeta] = useState([]);

  const openAlertPopup = (popupData) => {
    setShowAlertPopupFlag(true);
    setAlertPopupMeta(popupData);
  };

  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingTransfer, setLoadingTransfer] = useState(false);

  const selectedData =
    props.selectedData && props.selectedData.length > 0
      ? props.selectedData
      : [];

  const [showPaymentSlide, setShowPaymentSlide] = useState(false);
  const [showSuccessPopupFlag, setShowSuccessPopupFlag] = useState(false);

  const totalToPay = () => {
    let total = 0;

    selectedData.map((auction) => {
      let count = auction.total_bid - auction.total_pay;

      total = total + count;

      return false;
    });

    return total;
  };

  // Pay the auction selected
  const PayAuctionCard = () => {
    setLoadingCard(true);

    if (selectedData.length > 0) {
      var total_pay = totalToPay();

      sendCall("myCellar/PaymentAuctionNew/", "POST", {
        accessToken: user.accessToken,
        total_pay: total_pay,
        auctions_participant: selectedData,
      })
        .then((result) => {
          if (result.code === 200) {
            openAlertPopup({
              title: "Auctions were paid",
              subTitle: "",
              alertType: "success",
              onClose: () => {
                props.refresh();
                setLoadingCard(false);
                setLoadingCard(false);
              },
            });
          } else {
            //var textWarnings = result.warnings.join("<br>");
            var textWarnings = result.warnings.map((item, i) => {
              return (
                <div
                  key={"sdfiusdjhfkidjgofgjh" + i}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></div>
              );
            });

            if (result.code === 206) {
              console.log("Error function api:", result);

              openAlertPopup({
                title: "Not all Auctions were paid for",
                subTitle: <>{textWarnings}</>,
                alertType: "warning",
                onClose: () => {
                  props.refresh();
                  setLoadingCard(false);
                  setLoadingCard(false);
                },
              });
            } else {
              openAlertPopup({
                title: "Payment not made",
                subTitle: <>{textWarnings}</>,
                alertType: "warning",
                onClose: () => {
                  props.refresh();
                  setLoadingCard(false);
                  setLoadingCard(false);
                },
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);

          openAlertPopup({
            title: "Error on paid Auction",
            subTitle: "Please try again",
            alertType: "error",
            onClose: () => {
              props.refresh();
              setLoadingCard(false);
              setLoadingCard(false);
            },
          });
        });
    } else {
      openAlertPopup({
        title: "No row selected",
        subTitle: "Please try again",
        alertType: "warning",
        onClose: () => {
          setLoadingCard(false);
        },
      });
    }
  };

  // Pay the auction selected
  const PopPupBank = () => {
    setLoadingTransfer(true);

    if (user.method_payment?.permissions.is_enabled > 0) {
      var text_alert =
        "You have to change your payment method to pay by bank transfer";
    } else {
      text_alert = "You must apply to enable payments by bank transfer";
    }

    // Swal.fire({
    //     title: 'Attention',
    //     text: text_alert,
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Yes, change method payment'
    // }).then((result) => {
    //     setLoadingTransfer(false);
    //     if (result.isConfirmed) {

    //         window.location.href = '/user-profile/method-payments';

    //     }
    // })

    setLoadingTransfer(false);
    openAlertPopup({
      title: "Attention",
      subTitle: text_alert,
      alertType: "question",
      confirmButton: "Yes, change method payment",
      closeButton: "No, Cancel",
      onClose: () => {},
      onConfirmButton: () =>
        (window.location.href = "/user-profile/method-payments"),
    });
  };

  return (
    <>
      {!!showSuccessPopupFlag && (
        <SuccessPopup
          title={`Payment Successful!`}
          subTitle={`Your payment has been precessed`}
          hidePopup={() => {
            setShowSuccessPopupFlag(false);
          }}
          actionsOnHide={() => {
            setShowPaymentSlide(false);
            props.refresh();
            document.body.style.overflow = "visible";
          }}
        />
      )}

      {!!showPaymentSlide && (
        <Drawer
          title="Pay with Bank Transfer"
          position="right"
          width={570}
          onClosed={() => setShowPaymentSlide(false)}
          render={({ close }) => (
            //TODO: Rewrite this component
            <SlidePaymentRequest
              selectedData={selectedData}
              userProfile={user}
              config={config}
              refresh={props.refresh}
              totalToPay={totalToPay}
              closeSlide={close}
            />
          )}
        />
      )}

      <div className="collections-filter-bar" style={{ flexDirection: "row" }}>
        <div className="cfb-left" style={{ flexGrow: 1, width: "auto" }} />
        <div
          className="cfb-right"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "0.5rem",
            justifyContent: "center",
            width: "auto",
          }}
        >
          {user.method_payment?.active.name === "CARD" && (
            <Button
              style={{ flexGrow: 1, maxWidth: "300px" }}
              label={
                <span>
                  Pay with card
                  {!!totalToPay() && (
                    <span>&nbsp;({formatCurrency(totalToPay())})</span>
                  )}
                </span>
              }
              disabled={!totalToPay() || !!loadingCard}
              isLoading={loadingCard}
              onClick={(e) => PayAuctionCard(e)}
            />
          )}

          <Button
            style={{ flexGrow: 1, maxWidth: "300px" }}
            label={
              <span>
                Pay with bank transfer
                {!!totalToPay() && (
                  <span>&nbsp;({formatCurrency(totalToPay())})</span>
                )}
              </span>
            }
            disabled={!totalToPay() || !!loadingTransfer || !!loadingCard}
            isLoading={loadingTransfer}
            onClick={(e) =>
              user.roles === 2 &&
              user.method_payment?.active.name === "BANK-TRANSFER"
                ? (setShowPaymentSlide(true),
                  (document.body.style.overflow = "hidden"))
                : PopPupBank(e)
            }
          />
        </div>
      </div>

      {!!showAlertPopupFlag && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setShowAlertPopupFlag(false)}
        />
      )}
    </>
  );
}
