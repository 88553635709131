import styles from "./store-controls.module.css";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { noop } from "lodash";
import { ReactNode, useState } from "react";
import { Button } from "src/app/components/button";
import { useCardCtx } from "../../card-context";
import { ID_AUCTION_TYPE } from "src/app/const";
import { isAuctionOfLot } from "src/app/models/auctions";
import { useMyUser } from "src/app/hooks/useMyUser";
import AddInfoAndBuyDialog from "../../atoms/add-info-and-buy-dialog";

export const StoreControls = () => {
  const cardCtx = useCardCtx();
  const mul = cardCtx.auction.options.quantities.mul;
  const price = cardCtx.auction.initial_price;
  const isEnprimeur = cardCtx.auction.type.id === ID_AUCTION_TYPE.EN_PRIMEUR;
  const showInputQuantity = isEnprimeur
    ? isAuctionOfLot(cardCtx.auction) && !cardCtx.auction.canAddToWaitingList
    : true;

  const user = useMyUser();
  const hasCard = !user.checkUser.is_missing_card;

  const [showAddressAndCardModal, setShowAddressAndCardModal] = useState(false);

  return (
    <>
      {!!showAddressAndCardModal && (
        <AddInfoAndBuyDialog
          onClose={() => setShowAddressAndCardModal(false)}
          onBuy={() => {
            setShowAddressAndCardModal(false);
            cardCtx.handleSubmit();
          }}
        />
      )}
      <div className={styles.root}>
        <Line />

        <div
          className={styles.inner}
          style={!showInputQuantity ? { gridTemplateColumns: "auto" } : {}}
        >
          {!!showInputQuantity && (
            <InputQuantity
              greyedOut={!cardCtx.auction.canBid}
              quantity={cardCtx.quantity}
              onChange={cardCtx.setQuantity}
              renderQuantity={(quantity) =>
                mul ? `${quantity * mul} L` : quantity
              }
              quantityOptions={cardCtx.auction.quantityOptions.map(
                (el) => el.value,
              )}
            />
          )}
          {isAuctionOfLot(cardCtx.auction) &&
          !!isEnprimeur &&
          cardCtx.auction.canAddToWaitingList ? (
            <Button
              variant="outlined"
              label={
                <span>
                  <span>{`${cardCtx.auction.addedToWaitingList ? "Remove from" : "Add to"} waiting list`}</span>
                </span>
              }
              isLoading={cardCtx.isEnprimeurWaitingListLoading}
              disabled={cardCtx.isEnprimeurWaitingListLoading}
              style={{ width: "100%" }}
              onClick={() =>
                isAuctionOfLot(cardCtx.auction) &&
                cardCtx.auction.addedToWaitingList
                  ? cardCtx.handleRemoveEnprimeurToWaitingList()
                  : cardCtx.handleAddEnprimeurToWaitingList()
              }
            />
          ) : (
            <Button
              label={
                <span>
                  <span style={{ opacity: 0.66 }}> Buy for </span>
                  <span>{formatCurrency(cardCtx.quantity * price)}</span>
                </span>
              }
              forbidden={
                !cardCtx.auction.canBid &&
                !!user.addressInvoice.length &&
                !!hasCard
              }
              isLoading={cardCtx.isMakeABidLoading}
              disabled={cardCtx.isMakeABidLoading}
              className={styles.button}
              onClick={() =>
                (!user.addressInvoice.length || !hasCard) &&
                user.id_customer_role !== 14
                  ? setShowAddressAndCardModal(true)
                  : cardCtx.handleSubmit()
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

function Line() {
  return <div className={styles.line} />;
}

type InputQuantityProps = {
  quantity: number;
  greyedOut: boolean;
  onChange: (quantity: number) => void;
  renderQuantity: (quantity: number) => ReactNode;
  quantityOptions: number[];
};

const InputQuantity = ({
  quantity,
  greyedOut = false,
  onChange = noop,
  renderQuantity = (qty) => qty,
  quantityOptions,
}: InputQuantityProps) => {
  return (
    <div className={`${styles.inputQty} ${greyedOut ? styles.greyedOut : ""}`}>
      <button
        disabled={greyedOut || quantityOptions.indexOf(quantity) - 1 < 0}
        className={`${styles.minus} ${greyedOut ? styles.greyedOut : ""}`}
        onClick={() =>
          onChange(quantityOptions[quantityOptions.indexOf(quantity) - 1])
        }
      >
        -
      </button>
      <div className={styles.value}>{renderQuantity(quantity)}</div>
      <button
        disabled={
          greyedOut ||
          quantityOptions.indexOf(quantity) + 1 >= quantityOptions.length
        }
        className={styles.plus}
        onClick={() =>
          onChange(quantityOptions[quantityOptions.indexOf(quantity) + 1])
        }
      >
        +
      </button>
    </div>
  );
};
