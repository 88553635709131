import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "../../../LayoutSide/components/common/controls/CopyToClipboard";
import SVG from "react-inlinesvg";
import { AlertPopup } from "../../../LayoutSide/components/common/alert-popup";
import { sendCall } from "../../../../../config/Utility";
import iconMail from "src/media/svg/icons/Communication/Sending mail.svg";
import iconCheckSrc from "src/media/svg/icons/Navigation/Check.svg";

export function SlidePaymentRequest({
  closeSlide,
  selectedData,
  userProfile,
  refresh,
  config,
  totalToPay,
}) {
  const [, setSlideActive] = useState("");
  const [activeCount, setActiveCount] = useState(0);

  const [showAlertPopupFlag, setShowAlertPopupFlag] = useState(false);
  const [alertPopupMeta, setAlertPopupMeta] = useState([]);

  const openAlertPopup = (popupData) => {
    setShowAlertPopupFlag(true);
    setAlertPopupMeta(popupData);
  };

  const [loadingPaid, setLoadingPaid] = useState(false);
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  const auctions_participant = selectedData.map((item) => {
    if (item.cellar_type === "lots") {
      return "lot-" + item.id_auction_participant;
    }

    if (item.cellar_type === "barrels") {
      return "barrel-" + item.id_barrel_auction_participant;
    }

    return false;
  });

  const MarkPaid = () => {
    if (selectedData.length > 0) {
      openAlertPopup({
        title: "Are you sure?",
        subTitle: "You won't be able to revert this!",
        alertType: "question",
        confirmButton: "Yes, confirm it",
        closeButton: "No, Cancel",
        onClose: () => {},
        onConfirmButton: () => {
          setLoadingPaid(true);

          sendCall("myCellar/MarkPaidNew/", "POST", {
            accessToken: userProfile.accessToken,
            auctions_participant: selectedData,
          })
            .then((result) => {
              if (result.code === 200) {
                openAlertPopup({
                  title: "Auctions mark as paid",
                  subTitle: "",
                  alertType: "success",
                  onClose: () => {
                    refresh();
                    closeSlide();
                    setLoadingPaid(false);
                  },
                });
              } else {
                console.log("Error function api:", result);

                openAlertPopup({
                  title: "Error on mark paid Auction",
                  subTitle: "Please try again",
                  alertType: "error",
                  onClose: () => {
                    refresh();
                    closeSlide();
                    setLoadingPaid(false);
                  },
                });
              }
            })
            .catch((error) => {
              console.error("Error:", error);

              openAlertPopup({
                title: "Error on mark paid Auction",
                subTitle: "Please try again",
                alertType: "error",
                onClose: () => {
                  refresh();
                  closeSlide();
                  setLoadingPaid(false);
                },
              });
            });
        },
      });
    } else {
      openAlertPopup({
        title: "No row selected",
        subTitle: "Please try again",
        alertType: "warning",
        onClose: () => {
          setLoadingPaid(false);
        },
      });
    }
  };

  const SendInformationViaEmail = () => {
    if (selectedData.length > 0) {
      setLoadingSendEmail(true);

      sendCall("myCellar/SendInformationViaEmail/", "POST", {
        accessToken: userProfile.accessToken,
        purpose: purpose,
        import: totalToPay(),
        dataBankTransfer: config?.dataBankTransfer,
      })
        .then((result) => {
          if (result.code === 200) {
            openAlertPopup({
              title: "Email Send",
              subTitle: "",
              alertType: "success",
              onClose: () => {
                refresh();
                closeSlide();
                setLoadingSendEmail(false);
              },
            });
          } else {
            console.log("Error function api:", result);

            openAlertPopup({
              title: "Error on Send email",
              subTitle: "Please try again",
              alertType: "error",
              onClose: () => {
                refresh();
                closeSlide();
                setLoadingSendEmail(false);
              },
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);

          openAlertPopup({
            title: "Error on Send email",
            subTitle: "Please try again",
            alertType: "error",
            onClose: () => {
              refresh();
              closeSlide();
              setLoadingSendEmail(false);
            },
          });
        });
    } else {
      openAlertPopup({
        title: "No row selected",
        subTitle: "Please try again",
        alertType: "warning",
        onClose: () => {
          setLoadingSendEmail(false);
        },
      });
    }
  };

  const purpose =
    "Customer " +
    userProfile.fullname +
    " ID: " +
    userProfile.id +
    ' - Payment for offers "' +
    auctions_participant +
    '"';

  useEffect(() => {
    if (activeCount === 0) {
      setTimeout(function () {
        setSlideActive("active");
      }, 100);
      setActiveCount(activeCount + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="slide-shipping-order-details">
        <div className="ssod-blocks-list">
          <div className="ssod-blocks-list-item">
            <h4>Details</h4>
            <div className="ssod-rows">
              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">Import:</div>
                <div className="ssod-row-col ssod-row-col-2">
                  {totalToPay()}€ <CopyToClipboard TextToCopy={totalToPay()} />
                </div>
              </div>
            </div>
          </div>

          <div className="ssod-blocks-list-item">
            <h4>Instructions Bank Transfer</h4>
            <div className="ssod-rows">
              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">Beneficiary:</div>
                <div className="ssod-row-col ssod-row-col-2">
                  {config?.dataBankTransfer.Beneficiary}{" "}
                  <CopyToClipboard
                    TextToCopy={config?.dataBankTransfer.Beneficiary}
                  />
                </div>
              </div>

              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">IBAN:</div>
                <div className="ssod-row-col ssod-row-col-2">
                  {config?.dataBankTransfer.IBAN}{" "}
                  <CopyToClipboard TextToCopy={config?.dataBankTransfer.IBAN} />
                </div>
              </div>

              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">BIC:</div>
                <div className="ssod-row-col ssod-row-col-2">
                  {config?.dataBankTransfer.BIC}{" "}
                  <CopyToClipboard TextToCopy={config?.dataBankTransfer.BIC} />
                </div>
              </div>

              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">
                  Beneficiary address:
                </div>
                <div className="ssod-row-col ssod-row-col-2">
                  {config?.dataBankTransfer.BeneficiaryAddress}{" "}
                  <CopyToClipboard
                    TextToCopy={config?.dataBankTransfer.BeneficiaryAddress}
                  />
                </div>
              </div>

              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">
                  Bank / Payment institution:
                </div>
                <div className="ssod-row-col ssod-row-col-2">
                  {config?.dataBankTransfer.PaymentInstitution}{" "}
                  <CopyToClipboard
                    TextToCopy={config?.dataBankTransfer.PaymentInstitution}
                  />
                </div>
              </div>

              <div className="ssod-row">
                <div className="ssod-row-col ssod-row-col-1">
                  Bank / Payment institution address:
                </div>
                <div className="ssod-row-col ssod-row-col-2">
                  {config?.dataBankTransfer.PaymentInstitutionAddress}{" "}
                  <CopyToClipboard
                    TextToCopy={
                      config?.dataBankTransfer.PaymentInstitutionAddress
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="m-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                MarkPaid();
              }}
              disabled={loadingSendEmail || loadingPaid ? true : false}
            >
              <span className="svg-icon svg-icon-ms svg-icon-black">
                <SVG src={iconCheckSrc} />
              </span>
              Mark as paid
              {!!loadingPaid && (
                <span className="ml-3 spinner spinner-white"></span>
              )}
            </button>
            <button
              type="button"
              style={{ marginLeft: "5px" }}
              className="btn btn-primary"
              onClick={() => {
                SendInformationViaEmail();
              }}
              disabled={loadingSendEmail || loadingPaid ? true : false}
            >
              <span className="svg-icon svg-icon-ms svg-icon-black">
                <SVG src={iconMail} />
              </span>
              Send Information Via Email
              {!!loadingSendEmail && (
                <span className="ml-3 spinner spinner-white"></span>
              )}
            </button>
          </div>
        </div>
      </div>
      {!!showAlertPopupFlag && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setShowAlertPopupFlag(false)}
        />
      )}
    </React.Fragment>
  );
}
