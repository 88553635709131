import { Button } from "src/app/components/button";

type Props = {
  isLoading: boolean;
  forbidden: boolean;
  onClick: () => void;
  label: string;
};

export const ButtonSubmit = ({
  label,
  forbidden,
  isLoading,
  onClick,
}: Props) => {
  return (
    <Button
      style={{ height: "50px", fontSize: "16px", flexGrow: 1 }}
      label={label}
      forbidden={forbidden}
      isLoading={isLoading}
      onClick={onClick}
    />
  );
};
