import React, { useState } from "react";
import { UserProfileSidebar } from "../profile-sidebar";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { AlertPopup } from "../../../modules/Includes/LayoutSide/components/common/alert-popup";
import { sendCall } from "../../../config/Utility";
import infoCircleIconSrc from "src/media/svg/icons/Code/Info-circle.svg";
import { every, omit } from "lodash";
import { useMyUser } from "src/app/hooks/useMyUser";

export function MyPassword() {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("Error");
  const [isSuccess, setIsSuccess] = useState(false);
  const [successText, setSuccessText] = useState("Success");

  const user = useMyUser();

  const [showAlertPopupFlag, setShowAlertPopupFlag] = useState(false);
  const [alertPopupMeta, setAlertPopupMeta] = useState([]);

  const openAlertPopup = (popupData) => {
    setShowAlertPopupFlag(true);
    setAlertPopupMeta(popupData);
  };

  const saveUser = (values, setStatus, setSubmitting) => {
    setLoading(true);

    setTimeout(() => {
      sendCall("users/EditPasswordInformationUser/", "POST", {
        accessToken: user.accessToken,
        id_user: user.id,
        id_user_profile: values.id_user_profile,
        current_password: values.currentPassword,
        new_password: values.password,
      })
        .then((result) => {
          if (result.code === 200) {
            setLoading(false);
            setIsError(false);
            setIsSuccess(true);
            setSuccessText("Password has been changed");
            openAlertPopup({
              title: "Password has been changed",
              subTitle: "",
              alertType: "success",
              onClose: () => window.location.reload(),
            });
          } else {
            setLoading(false);
            setSubmitting(false);
            setIsError(true);
            setIsSuccess(false);
            setErrorText(result.message);

            openAlertPopup({
              title: "Error changing the password",
              subTitle: result.message,
              alertType: "error",
              onClose: () => window.location.reload(),
            });
          }
        })
        .catch(() => {
          setLoading(false);
          setSubmitting(false);
          setIsError(true);
          setIsSuccess(false);
          setErrorText("Internal Error");
        });
    }, 1000);
  };
  // UI Helpers
  const initialValues = {
    currentPassword: "",
    id_user_profile: user.id,
    password: "",
    cPassword: "",
  };
  const Schema = Yup.object().shape({
    id_user_profile: Yup.string(),
    currentPassword: Yup.string().required("Current password is required"),
    password: Yup.string()
      .min(8)
      .max(24)
      .matches(/.*\d.*/, "The password must contain at least one digit")
      .required("New Password is required"),
    cPassword: Yup.string()
      .label("confirm password")
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      saveUser(values, setStatus, setSubmitting, resetForm);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <>
      {!!showAlertPopupFlag && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setShowAlertPopupFlag(false)}
        />
      )}

      <div className="user-profile-container">
        <UserProfileSidebar />
        <div className="upc-mainbody">
          {/* begin::Alert */}
          {!!isError && (
            <div
              className="alert alert-custom alert-light-danger fade show mb-10"
              role="alert"
            >
              <div className="alert-icon">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  <SVG src={infoCircleIconSrc}></SVG>{" "}
                </span>
              </div>
              <div className="alert-text font-weight-bold">{errorText}</div>
              <div className="alert-close" onClick={() => setIsError(false)}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )}
          {!!isSuccess && (
            <div
              className="alert alert-custom alert-light-success fade show mb-10"
              role="alert"
            >
              <div className="alert-icon">
                <span className="svg-icon svg-icon-3x svg-icon-success">
                  <SVG src={infoCircleIconSrc}></SVG>{" "}
                </span>
              </div>
              <div className="alert-text font-weight-bold">{successText}</div>
              <div className="alert-close" onClick={() => setIsError(false)}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )}
          {/* end::Alert */}
          <div className="account-info-form">
            <form
              name="change-pasword-form"
              id="change-pasword-form"
              action=""
              method="POST"
            >
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="current-password">Current password*</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="password"
                      placeholder="Current Password"
                      className={`inputbox mb-2 ${getInputClasses(
                        "currentPassword",
                      )}`}
                      name="currentPassword"
                      {...formik.getFieldProps("currentPassword")}
                    />
                    {!!formik.touched.currentPassword &&
                      !!formik.errors.currentPassword && (
                        <div className="invalid-feedback">
                          {formik.errors.currentPassword}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="new-password">New password*</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="password"
                      placeholder="New Password"
                      className={`inputbox ${getInputClasses("password")}`}
                      name="password"
                      {...formik.getFieldProps("password")}
                    />
                    {!!formik.touched.password && !!formik.errors.password && (
                      <div className="invalid-feedback">
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="verify-password">Verify password*</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="password"
                      placeholder="Verify Password"
                      className={`inputbox ${getInputClasses("cPassword")}`}
                      name="cPassword"
                      {...formik.getFieldProps("cPassword")}
                    />
                    {!!formik.touched.cPassword &&
                      !!formik.errors.cPassword && (
                        <div className="invalid-feedback">
                          {formik.errors.cPassword}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="upc-form-row upc-form-btn-row">
                <div className="upc-form-row-left">
                  <label>&nbsp;</label>
                </div>
                <div className="upc-form-row-right">
                  <button
                    onClick={formik.handleSubmit}
                    className={`btn btn-primary mb-3 mr-3 ${
                      loading ? `btn-processing` : ``
                    }`}
                    style={{ boxSizing: "border-box" }}
                    disabled={
                      loading ||
                      !formik.isValid ||
                      every(
                        omit(formik.values, ["id_user_profile"]),
                        (val) => !val,
                      )
                    }
                  >
                    Save Changes
                  </button>
                  <Link
                    to="/user-profile/profile-overview"
                    className="btn btn-secondary btn-outlined mb-3"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
