import styles from "./button-share.module.css";
import * as Popover from "@radix-ui/react-popover";
import * as Portal from "@radix-ui/react-portal";
import { MdContentCopy } from "react-icons/md";
import clipboardCopy from "copy-to-clipboard";
import { BsTwitterX } from "react-icons/bs";
import { LuChevronsUpDown } from "react-icons/lu";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toPx } from "src/app/utils/toPx";
import { useState } from "react";
import { Tooltip } from "../../tooltip";
import { Auction } from "src/app/models/auctions";
import { path } from "src/app/routes/path";
import { Button } from "../../button";
import { useCardCtx } from "../card-context";

const iconSize = 30;
const iconRadius = 12;

export const ButtonShare = () => {
  const { auction } = useCardCtx();
  const shareURL = `${location.origin}${path.sales.value(auction)}`;
  const [showMore, setShowMore] = useState(false);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div style={{ display: "inline-flex" }}>
          <Button
            variant="ghost"
            icon={<IconShare />}
            tooltip="Share"
            className={styles.button}
          />
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          onEscapeKeyDown={() => setShowMore(false)}
          onPointerDownOutside={() => setShowMore(false)}
          onFocusOutside={() => setShowMore(false)}
          onInteractOutside={() => setShowMore(false)}
          align="end"
          className={styles.content}
        >
          {/* prevent focus on facebook icon */}
          <button
            style={{
              position: "absolute",
              pointerEvents: "none",
              opacity: 0,
              width: 0,
            }}
          />

          <Tooltip content="Facebook">
            <FacebookShareButton url={shareURL}>
              <FacebookIcon borderRadius={iconRadius} size={iconSize} />
            </FacebookShareButton>
          </Tooltip>
          {/* <Tooltip content="Messenger">
            <FacebookMessengerShareButton url={shareURL}>
              <FacebookMessengerIcon borderRadius={iconRadius} size={iconSize} />
            </FacebookMessengerShareButton>
          </Tooltip> */}
          <Tooltip content="X">
            <TwitterShareButton url={shareURL}>
              <BsTwitterX className={styles.twitterIcon} size={iconSize} />
            </TwitterShareButton>
          </Tooltip>
          <Tooltip content="Whatsapp">
            <WhatsappShareButton url={shareURL}>
              <WhatsappIcon borderRadius={iconRadius} size={iconSize} />
            </WhatsappShareButton>
          </Tooltip>
          <Tooltip content="Telegram">
            <div>
              <TelegramShareButton url={shareURL}>
                <TelegramIcon borderRadius={iconRadius} size={iconSize} />
              </TelegramShareButton>
            </div>
          </Tooltip>
          <CopyToClipboardButton
            borderRadius={iconRadius}
            size={iconSize}
            auction={auction}
          />

          <Button
            className={styles.showMore}
            onClick={() => setShowMore((x) => !x)}
            tooltip={showMore ? "Show less options" : "Show more options"}
            icon={<LuChevronsUpDown size={20} />}
            size={iconSize}
          />

          {!!showMore && (
            <>
              <Tooltip content="Email">
                <div>
                  <EmailShareButton
                    url={shareURL}
                    subject={auction.description}
                  >
                    <EmailIcon borderRadius={iconRadius} size={iconSize} />
                  </EmailShareButton>
                </div>
              </Tooltip>
              <Tooltip content="Linkedin">
                <LinkedinShareButton url={shareURL}>
                  <LinkedinIcon borderRadius={iconRadius} size={iconSize} />
                </LinkedinShareButton>
              </Tooltip>
              <Tooltip content="Weibo">
                <WeiboShareButton url={shareURL}>
                  <WeiboIcon borderRadius={iconRadius} size={iconSize} />
                </WeiboShareButton>
              </Tooltip>
              <Tooltip content="VK">
                <VKShareButton url={shareURL}>
                  <VKIcon borderRadius={iconRadius} size={iconSize} />
                </VKShareButton>
              </Tooltip>
              <Tooltip content="Reddit">
                <RedditShareButton url={shareURL}>
                  <RedditIcon borderRadius={iconRadius} size={iconSize} />
                </RedditShareButton>
              </Tooltip>
            </>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

type CopyToClipboardProps = {
  size?: string | number;
  borderRadius?: string | number;
  auction: Auction;
};

function CopyToClipboardButton({
  size,
  borderRadius,
  auction,
}: CopyToClipboardProps) {
  const hideAfterMs = 3000;
  const [hasCopied, setHasCopied] = useState(false);

  return (
    <>
      <Button
        className={styles.copyBtn}
        style={{
          width: toPx(size),
          height: toPx(size),
          borderRadius: toPx(Number(borderRadius) / 3),
        }}
        tooltip="Copy to clipboard"
        onClick={() => {
          const shareURL = `https://members.crurated.com${path.sales.value(
            auction,
          )}`;

          clipboardCopy(shareURL);
          setHasCopied(true);
          setTimeout(() => setHasCopied(false), hideAfterMs);
        }}
        disabled={hasCopied}
        icon={<MdContentCopy size={18} color="white" />}
      />

      {!!hasCopied && (
        <Portal.Root
          container={document.getElementById("root")}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          style={{ "--hide-after-ms": `${hideAfterMs}ms` }}
        >
          <SuccessPopup />
        </Portal.Root>
      )}
    </>
  );
}

function SuccessPopup() {
  return (
    <div className={styles.successPopup} role="alert">
      Copied to clipboard!
    </div>
  );
}

function IconShare() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15833 11.2577L12.85 14.5743M12.8417 5.42435L7.15833 8.74102M17.5 4.16602C17.5 5.54673 16.3807 6.66602 15 6.66602C13.6193 6.66602 12.5 5.54673 12.5 4.16602C12.5 2.7853 13.6193 1.66602 15 1.66602C16.3807 1.66602 17.5 2.7853 17.5 4.16602ZM7.5 9.99935C7.5 11.3801 6.38071 12.4993 5 12.4993C3.61929 12.4993 2.5 11.3801 2.5 9.99935C2.5 8.61864 3.61929 7.49935 5 7.49935C6.38071 7.49935 7.5 8.61864 7.5 9.99935ZM17.5 15.8327C17.5 17.2134 16.3807 18.3327 15 18.3327C13.6193 18.3327 12.5 17.2134 12.5 15.8327C12.5 14.452 13.6193 13.3327 15 13.3327C16.3807 13.3327 17.5 14.452 17.5 15.8327Z"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
