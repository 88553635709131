import { Switch } from "react-router-dom";
import { Layout } from "../../layout.public";
import { SentryRoute } from "src/app/routes/sentry-route";
import { path } from "src/app/routes/path";
import { PlanSelection } from "./plan-selection";
import { RegistrationSteps } from "./registration-steps";

export const PageSignup = () => {
  return (
    <Layout>
      <Switch>
        <SentryRoute
          path={path.signup.steps.definition()}
          component={RegistrationSteps}
        />
        <SentryRoute
          path={path.signup.planSelection()}
          component={PlanSelection}
        />
      </Switch>
    </Layout>
  );
};
