import styles from "./WhatsNewSlider.module.css";
import { Fragment, useRef } from "react";
import { CardForAuction } from "src/app/components/card-for-auction";
import { ID_AUCTION_TYPE, PAGE_NAME, TYPE_PRODUCT } from "src/app/const";
import { useClickAndDragScrollX } from "src/app/hooks/useClickAndDragScrollX";
import { useScrollToNextOrPrevChild } from "src/app/hooks/useScrollToNextOrPrevChild";
import { useGetWhatsNewQuery } from "src/app/api/queries_to_rename_later";
import { path } from "src/app/routes/path";
import { noop } from "lodash";
import { BarrelInput } from "src/app/components/barrel-input";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { cn } from "src/app/utils/cn";
import { useHistory } from "react-router-dom";
import { slugify } from "src/app/utils/slugify";
import { ApiAuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { ApiAuctionOfLot } from "src/app/models/auctions/AuctionOfLot";

export function WhatsNewSlider() {
  const whatsNewQuery = useGetWhatsNewQuery();
  const isMobile = useIsMobile();
  const listRef = useRef<HTMLDivElement | null>(null);
  const { scrollToNext, scrollToPrev } = useScrollToNextOrPrevChild(listRef);
  useClickAndDragScrollX(listRef);

  return (
    <section className={cn(styles.root, !isMobile && styles.desktop)}>
      <div className={styles.header}>
        <h3 className={styles.title}>What{"'"}s new</h3>
        <div className={styles.buttonContainer}>
          <button className={styles.button} onClick={scrollToPrev} />
          <button className={styles.button} onClick={scrollToNext} />
        </div>
      </div>

      <div ref={listRef} className={styles.list}>
        {!!whatsNewQuery.isLoading && (
          <Fragment>
            <CardForAuction.SkeletonMd />
            <CardForAuction.SkeletonMd />
            <CardForAuction.SkeletonMd />
            <CardForAuction.SkeletonMd />
          </Fragment>
        )}

        {!whatsNewQuery.isLoading &&
          whatsNewQuery.data?.map((entry, index) => (
            <Fragment key={`whats-new-card-${index}`}>
              {entry.type_product === TYPE_PRODUCT.LOT ? (
                <CardForLot auction={entry} style={{ marginBottom: "2px" }} />
              ) : (
                <CardForBarrel
                  auction={entry}
                  style={{ marginBottom: "2px" }} // otherwise the border bottom is hidden
                />
              )}
            </Fragment>
          ))}
      </div>
    </section>
  );
}

type CardForBarrelProps = {
  auction: ApiAuctionOfBarrel;
  className?: string;
  style?: Record<string, any>;
};

const CardForBarrel = ({ auction, className, style }: CardForBarrelProps) => {
  const history = useHistory();
  const salesLink = `/sales/barrel/${auction.id}-${slugify(
    auction.description ?? undefined,
  )}`;

  return (
    <CardForAuction.Root className={className} style={style}>
      <CardForAuction.Countdown targetDate={auction.finish_data} />
      <CardForAuction.Title
        linkTo={salesLink}
        title={auction.description}
        style={{
          paddingRight: auction.only_for_you || auction.is_new ? "12px" : 0,
        }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <CardForAuction.Subtitle subtitle={auction.barrel.builder.name} />
        <CardForAuction.ViewDetails onClick={() => history.push(salesLink)} />
      </div>

      <div style={{ display: "flex", alignItems: "center" }}>
        <CardForAuction.CatalogueLink to={auction.catalogue} />
      </div>

      <div style={{ flexGrow: 1, alignItems: "flex-start" }}>
        <CardForAuction.TabsForBarrels
          auction={auction}
          fallbackPanel={
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BarrelInput
                color={auction.barrel.color}
                barrelHeight={160}
                max={auction.options.quantities.max}
                min={auction.options.quantities.min}
                step={auction.options.quantities.step}
                mul={auction.options.quantities.mul}
                quantity={Math.max(
                  auction.options.quantities.min,
                  Math.ceil(auction.options.quantities.max / 10),
                )}
                onChange={noop}
                hideTotalLiters
              />
            </div>
          }
        />
      </div>

      <CardForAuction.Link
        text="Go to Barrel"
        to={path.weekly.value(PAGE_NAME.WEEKLY.BARRELS, auction.id) + "&page=3"}
      />
    </CardForAuction.Root>
  );
};

type CardForLotProps = {
  auction: ApiAuctionOfLot;
  className?: string;
  style?: Record<string, any>;
};

function CardForLot({ auction, className, style }: CardForLotProps) {
  const history = useHistory();

  if (!auction.lot) {
    return null;
  }

  const salesLink = `/sales/lot/${auction.id}-${slugify(auction.description ?? undefined)}`;

  return (
    <CardForAuction.Root className={className} style={style}>
      <CardForAuction.Countdown targetDate={auction.finish_data} />
      <CardForAuction.Title
        linkTo={salesLink}
        title={auction.description}
        style={{
          paddingRight: auction.only_for_you || auction.is_new ? "12px" : 0,
        }}
      />
      <CardForAuction.ViewDetails onClick={() => history.push(salesLink)} />
      <CardForAuction.CatalogueLink to={auction.catalogue} />
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          marginBottom: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #ccc",
            paddingBottom: "12px",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <CardForAuction.BottleCount lot={auction.lot} />
        </div>
        <div
          className="show-scrollbars"
          style={{
            flexGrow: 1,
            alignItems: "flex-start",
            minHeight: "150px",
            maxHeight: "200px",
          }}
        >
          <CardForAuction.ProductList products={auction.lot.components} />
        </div>
      </div>

      <CardForAuction.Link
        text={getLinkTextForLots(auction.type.id)}
        to={
          // TODO: review
          auction.type.id === ID_AUCTION_TYPE.SINGLE_LOTS
            ? path.store.shop()
            : auction.type.id === ID_AUCTION_TYPE.EN_PRIMEUR ||
                auction.type.slug === "en-primeur"
              ? path.store.enPrimeur()
              : auction.type.id === ID_AUCTION_TYPE.PRIVATE_SALE ||
                  auction.type.slug === "private-sale"
                ? path.store.privateSale()
                : auction.type.id === ID_AUCTION_TYPE.PERMANENT ||
                    auction.type.slug === "permanent-collections"
                  ? auction.type.slug
                  : path.weekly.value(auction.type.slug, auction.id) + "&page=3"
        }
      />
    </CardForAuction.Root>
  );
}

function getLinkTextForLots(idAuctionType: number) {
  const prefix = "Go to ";

  if (idAuctionType === ID_AUCTION_TYPE.COLLECTION) {
    return prefix + "Collection";
  }

  if (idAuctionType === ID_AUCTION_TYPE.SINGLE_LOTS) {
    return prefix + "Single Lot";
  }

  if (idAuctionType === ID_AUCTION_TYPE.PRIVATE_SALE) {
    return prefix + "Private Sale";
  }

  if (idAuctionType === ID_AUCTION_TYPE.EN_PRIMEUR) {
    return prefix + "En Primeur";
  }

  return prefix + "Auction";
}
