import { useState } from "react";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";

export function BannerSuccess({
  message,
  linkTo,
  linkText,
}: {
  message: string;
  linkTo: string;
  linkText: string;
}) {
  const [show, setShow] = useState(true);

  if (!show) return null;

  return (
    <Alert
      variant="secondary"
      style={{ marginTop: "20px" }}
      onClose={() => setShow(false)}
    >
      <div className="container-fluid p-0 py-md-2 px-md-4">
        <div
          className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between"
          style={{ gap: "1rem 4rem" }}
        >
          <div>
            <Alert.Heading className="text-burgundy">
              <FaCheck style={{ color: "green", marginRight: "0.5rem" }} />
              <b>Congratulations!</b>
            </Alert.Heading>
            <p
              className="text-burgundy"
              style={{ fontSize: "0.875rem" }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </div>
          {!!linkTo && !!linkText && (
            <Link
              to={linkTo}
              className="btn btn-light-primary"
              style={{ fontWeight: 600, paddingInline: "20px" }}
            >
              {linkText}
            </Link>
          )}
        </div>
      </div>
    </Alert>
  );
}
