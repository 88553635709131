import { Stripe, StripeElements } from "@stripe/stripe-js";
import {
  SubscriptionV1,
  apiSubscriptionV1Schema,
} from "../models/SubscriptionV1";
import { SignupFormValues } from "../pages/sign-up/registration-steps";
import { upfetch, upfetchV1 } from "./upfetch";
import { z } from "zod";
import { CardNumberElement } from "@stripe/react-stripe-js";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { QKEY } from "./QKEY";
import { useDebounceValue } from "../hooks/useDebounceValue";
import { minutes } from "../utils/time";
import { gaEventConfig, triggerGaEvent } from "../models/GaEvent";
import { CouponV1, couponSchemaV1 } from "../models/Coupon";
import { createEtheriumWallet } from "../web3";
import CryptoAES from "crypto-js/aes";
import { env } from "src/env";
import { useMyUser } from "../hooks/useMyUser";
import { apiMySubscriptionsV1Schema } from "../models/MySubscriptionsV1";

const getSubscriptionPlans = () => ({
  queryKey: [QKEY.SUBSCRIPTIONS],
  queryFn: async () =>
    upfetchV1({
      url: "users/GetAllSubscriptionsActiveUserNew/",
      parseResponse: (res) => res.json().then((data) => data.result || []),
      schema: z.array(apiSubscriptionV1Schema),
    }),
});

export const useSubscriptionPlans = (options = {}) =>
  useQuery({
    queryKey: getSubscriptionPlans().queryKey,
    queryFn: getSubscriptionPlans().queryFn,
    staleTime: minutes(10),
    ...options,
  });

const checkEmail = (email: string) => ({
  queryKey: [QKEY.CHECK_EMAIL, email],
  queryFn: () => {
    return upfetch({
      url: "users/checkEmail/",
      method: "POST",
      body: { email },
      schema: z.object({
        already_used: z.boolean(),
        email: z.string(),
      }),
    }).catch(() => ({ already_used: false, email }));
  },
});

export function useCheckEmailQuery(
  email: string,
  options: UseQueryOptions<{ already_used: boolean; email: string }> = {},
) {
  const debouncedEmail = useDebounceValue(options.enabled ? email : "", 500);

  return useQuery({
    queryKey: checkEmail(email).queryKey,
    queryFn: checkEmail(email).queryFn,
    staleTime: minutes(5),
    ...options,
    enabled: !!debouncedEmail && options.enabled,
  });
}

export function useCouponMutation(
  options: UseMutationOptions<CouponV1, any, string> = {},
) {
  return useMutation({
    ...options,
    mutationFn: (code) => {
      return upfetchV1({
        url: "users/GetCouponSelected/",
        method: "POST",
        body: { code },
        schema: couponSchemaV1,
      });
    },
  });
}

type PayAndSubscribePayload = {
  formValues: SignupFormValues;
  subscriptionPlan: SubscriptionV1;
  stripe: Stripe;
  stripeElements: StripeElements;
};

export const usePayAndSubscribeMutation = (
  options: UseMutationOptions<number, any, PayAndSubscribePayload> = {},
) => {
  const walletMutation = useCreateEtheriumWalletMutation();

  return useMutation({
    ...options,
    mutationFn: async ({
      formValues,
      subscriptionPlan,
      stripe,
      stripeElements,
    }) => {
      const isExplorer = subscriptionPlan.id_customer_role === 12;

      const stripeCustomer = await upfetchV1({
        url: "stripe/CreateCustomer/",
        method: "POST",
        body: {
          email: formValues.email,
          name: `${formValues.firstName} ${formValues.lastName}`,
          phone: formValues.phone,
        },
        schema: z.object({
          id_customer: z.string(),
          clientSecret: z.string(),
        }),
      });

      if (
        !isExplorer &&
        (!formValues.coupon.data ||
          formValues.coupon.data.check_credit_card_on_registration)
      ) {
        const cardNumberElement = stripeElements.getElement(CardNumberElement);

        if (!cardNumberElement) {
          throw new Error("CardNumberElement not found");
        }
        await stripe.confirmCardSetup(stripeCustomer.clientSecret, {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name: `${formValues.firstName} ${formValues.lastName}`,
            },
          },
        });
      }

      const shippingAddress = isExplorer
        ? undefined
        : formValues.shippingAddress;

      const billingAddress = isExplorer
        ? undefined
        : formValues.isBillingAddressTheSameAsShipping
        ? formValues.shippingAddress
        : formValues.billingAddress;

      const id_user = await upfetch({
        url: "users/customers/registration/",
        method: "POST",
        body: {
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          email: formValues.email,
          birthday: formValues.birthday,
          password: formValues.password,
          shipping_company_name: formValues.companyName,
          shipping_address_line_1: shippingAddress?.address_line_1,
          shipping_address_line_2: undefined,
          shipping_vat: formValues.vat,
          shipping_country: shippingAddress?.country,
          shipping_region: shippingAddress?.region,
          shipping_city: shippingAddress?.city,
          shipping_postal_code: shippingAddress?.postal_code,
          shipping_phone_number: formValues.phone,
          shipping_additional_information: formValues.shippingInfo,
          billing_company_name: formValues.companyName,
          billing_address_line_1: billingAddress?.address_line_1,
          billing_address_line_2: undefined,
          billing_vat: formValues.vat,
          billing_country: billingAddress?.country,
          billing_region: billingAddress?.region,
          billing_city: billingAddress?.city,
          billing_postal_code: billingAddress?.postal_code,
          billing_phone_number: formValues.phone,
          billing_additional_information: undefined,
          role_customer: subscriptionPlan.id_customer_role,
          id_subscription: subscriptionPlan.id_subscription,
          id_customer_stripe: stripeCustomer.id_customer,
          months_free: formValues.coupon.freeMonths,
          default_months_free: 0,
          id_coupon: formValues.coupon.data?.id_coupon,
          ref: formValues.ref,
          cruratedWarehouse: formValues.useWarehouse,
          consent_terms_and_conditions: formValues.termsAccepted,
          consent_profiling: formValues.profiling,
          consent_marketing: formValues.marketing,
        },
        schema: z.number(),
      });

      triggerGaEvent(gaEventConfig.signup(subscriptionPlan.id_customer_role));
      walletMutation.mutate({ id_user }); // no need to await
      return id_user;
    },
  });
};

export function useCreateEtheriumWalletMutation() {
  return useMutation({
    mutationFn({ id_user }: { id_user: number }) {
      const web3Account = createEtheriumWallet();
      const addressKey = web3Account.address;
      // TODO: do not encrypt on the FE
      const privateKey: string = CryptoAES.encrypt(
        JSON.stringify(web3Account.privateKey),
        env.REACT_APP_SECRET_KEY,
      ).toString();

      if (!addressKey || !privateKey) {
        return Promise.reject("Invalid web3Account");
      }

      return upfetchV1({
        url: "nft/CreateWalletUserNft/",
        method: "POST",
        body: { id_user, addressKey, privateKey },
      });
    },
  });
}

// TODO: We need backend to refactor this API
type UpgradeSubscriptionPayload = {
  subscription: SubscriptionV1 | undefined;
  type: "subscription" | "upgrade" | "downgrade";
};

export const useUpgradeSubscriptionMutation = (
  options: UseMutationOptions<any, any, UpgradeSubscriptionPayload> = {},
) => {
  const { id_user, id_customer_stripe } = useMyUser();
  return useMutation({
    ...options,
    mutationFn: async ({ subscription, type }) => {
      if (!subscription) throw new Error("Subscription not found");
      const res = await upfetchV1({
        url:
          type === "downgrade"
            ? "users/RequestSubscription/"
            : type === "upgrade"
            ? "users/UpgradeOrDowngradeSubscriptionUser/"
            : "users/SendSubscriptionUser/",
        body:
          type === "downgrade" || type === "upgrade"
            ? {
                id_user,
                id_customer_stripe,
                id_subscription: subscription.id_subscription,
                type: type === "downgrade" ? 1 : 2,
              }
            : {
                id_user,
                id_customer_stripe,
                id_subscription: subscription.id_subscription,
              },
        method: "POST",
      });

      if (res === -1) throw new Error("Payment not made");

      triggerGaEvent(
        gaEventConfig.subscription(
          subscription,
          type === "subscription" ? "create" : type,
        ),
      );

      return res;
    },
  });
};

const getMySubscriptions = () => ({
  queryKey: [QKEY.ME, QKEY.SUBSCRIPTIONS],
  queryFn: async () =>
    upfetchV1({
      url: "users/GetMySubscriptionHandling/",
      parseResponse: (res) => res.json().then((data) => data.result || []),
      schema: z.array(apiMySubscriptionsV1Schema),
    }),
});

export const useMySubscriptionsQuery = (options = {}) =>
  useQuery({
    queryKey: getMySubscriptions().queryKey,
    queryFn: getMySubscriptions().queryFn,
    ...options,
  });

export function useCancelSubscriptionMutation(
  options: UseMutationOptions<
    any,
    any,
    { id_subscription_handling: number }
  > = {},
) {
  const { id_user, id_customer_stripe } = useMyUser();
  return useMutation({
    ...options,
    mutationFn: ({ id_subscription_handling }) => {
      return upfetchV1({
        url: "users/CancelSubscription/",
        method: "POST",
        body: { id_user, id_customer_stripe, id_subscription_handling },
      });
    },
  });
}

export function usePaySubscriptionMutation(
  options: UseMutationOptions<
    any,
    any,
    { id_subscription_handling: number }
  > = {},
) {
  const { id_user, id_customer_stripe } = useMyUser();
  return useMutation({
    ...options,
    mutationFn: ({ id_subscription_handling }) => {
      return upfetchV1({
        url: "users/PaySubscription/",
        method: "POST",
        body: { id_user, id_customer_stripe, id_subscription_handling },
      });
    },
  });
}
