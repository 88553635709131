import { Suspense, lazy } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
import { Layout } from "../../_metronic/layout";
import { PageMyAssets } from "src/app/pages/my-assets";
import { WeeklyAuctionsPage } from "src/app/pages/weekly-auctions";
import { PageMyActivities } from "src/app/pages/my-activities";
import { path } from "./path";
import { PageSales } from "src/app/pages/sales";
import { Contact } from "src/app/pages/contact";
import { PageStore } from "../pages/store";
import { useMyUserQuery } from "../api/auth";
import UserProfilePage from "../pages/my-profile";
import { ErrorBoundary } from "../components/error-boundary";
import { SentryRoute } from "./sentry-route";
import { env } from "src/env";
import { ShipmentsPage } from "../pages/my-shipments";
import { PageError404 } from "../pages/errors/error-404";
import { PageErrorForbidden } from "../pages/errors/error-forbidden";
import { SplashScreen } from "../layouts/splash-screen";
import { useMount } from "react-use";
import { DashboardPage } from "../pages/dashboard";
import { OutstandingPaymentsPage } from "../pages/outstanding-payments";
import PendingGiftsPage from "../pages/pending-gifts";
import useBrazeInitializer from "../components/braze/use-braze-initializer";

const TestPage = lazy(() => import("../pages/test"));

export function RoutesPrivate() {
  const { data: user, isLoading, isError, isEnabled } = useMyUserQuery();
  const location = useLocation();

  useBrazeInitializer();

  if (isEnabled && isLoading) {
    return <SplashScreen />;
  }

  if (isError || !user?.id || !isEnabled) {
    return (
      <Switch>
        <SentryRoute
          path={path.sales.definition()}
          component={RedirectToWebsiteSalesPage}
        />
        <SentryRoute>
          <Redirect
            to={{ pathname: path.logout(), state: { from: location } }}
          />
        </SentryRoute>
      </Switch>
    );
  }

  return (
    <Layout>
      <ErrorBoundary>
        <Suspense fallback={<SplashScreen />}>
          <Switch>
            <Redirect
              exact
              from="/"
              // redirect allo shop di default fino al 26 agosto
              to={
                new Date() < new Date("08-26-2024")
                  ? path.store.shop()
                  : path.dashboard()
              }
            />
            <Redirect from="/error" exact={true} to={path[404]()} />
            <SentryRoute path={path[404]()} component={PageError404} />
            <SentryRoute
              path={path.forbidden()}
              component={PageErrorForbidden}
            />

            <SentryRoute path={path.dashboard()} component={DashboardPage} />
            <SentryRoute path={path.sales.definition()} component={PageSales} />
            <SentryRoute path={path.store.definition()} component={PageStore} />
            <SentryRoute
              path={path.weekly.definition()}
              component={WeeklyAuctionsPage}
            />

            <SentryRoute
              path="/outstanding-payments"
              component={OutstandingPaymentsPage}
            />
            <SentryRoute path="/shipments" component={ShipmentsPage} />
            <SentryRoute path={path.contact()} component={Contact} />
            <SentryRoute path="/user-profile" component={UserProfilePage} />
            <SentryRoute
              path={path.myAssets.value()}
              component={PageMyAssets}
            />
            <SentryRoute
              path={path.myActivities.definition()}
              component={PageMyActivities}
            />
            <SentryRoute path="/pendingGifts" component={PendingGiftsPage} />

            {!env.isProd && <SentryRoute path={"/test"} component={TestPage} />}
            <Redirect to={path[404]()} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </Layout>
  );
}

function RedirectToWebsiteSalesPage({ location }) {
  useMount(() => {
    window.location.href = `${env.REACT_APP_WEBSITE}${location.pathname}`;
  });
  return <SplashScreen />;
}
