import { Button } from "src/app/components/button";

type Props = {
  onClick: () => void;
  isArchived: boolean;
  isLoading: boolean;
};

export const ButtonArchive = ({ onClick, isArchived, isLoading }: Props) => {
  return (
    <Button
      variant="outlined"
      size={50}
      onClick={() => onClick()}
      tooltip={isArchived ? "Remove from your archive" : "Add to your archive"}
      isLoading={isLoading}
      icon={isArchived ? <IconArchiveContained /> : <IconArchiveOutlined />}
    />
  );
};

function IconArchiveContained() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.52344 16.1369V7.58691H15.8234V16.1369H3.52344ZM2.02344 3.83691V6.38691H17.3234V3.83691H2.02344ZM8.17344 9.38691C7.84207 9.38691 7.57344 9.65554 7.57344 9.98691C7.57344 10.3183 7.84207 10.5869 8.17344 10.5869H11.1734C11.5048 10.5869 11.7734 10.3183 11.7734 9.98691C11.7734 9.65554 11.5048 9.38691 11.1734 9.38691H8.17344Z"
        fill="currentColor"
      />
    </svg>
  );
}

function IconArchiveOutlined() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4899_15953)">
        <path
          d="M16.4219 6.5V16.25H2.92188V6.5M8.17188 9.5H11.1719M1.42188 2.75H17.9219V6.5H1.42188V2.75Z"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4899_15953">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0.671875 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
