import { z } from "zod";
import {
  AuctionOfBarrel,
  apiAuctionOfBarrelSchema,
} from "../models/auctions/AuctionOfBarrel";
import {
  AuctionOfLot,
  apiAuctionOfLotSchema,
} from "../models/auctions/AuctionOfLot";
import { QKEY } from "./QKEY";
import { findIndex } from "lodash";
import { Auction, isAuctionOfBarrel, isAuctionOfLot } from "../models/auctions";
import { upfetch } from "./upfetch";
import { useMyUser } from "../hooks/useMyUser";
import {
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { ID_AUCTION_TYPE, ID_IO_ROOT, PageName, TypeProduct } from "../const";
import { useCountdown } from "../hooks/useCountdown";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import { useUpdateEffect } from "react-use";
import { useCount } from "../hooks/useCount";
import { SelectMultipleValues } from "../components/form-elements/select-multiple/types";

type UseAuctionsQueryConfig = {
  pageName: PageName;
  limit?: number;
  id_builder?: number;
};

const getAuctions = (config: UseAuctionsQueryConfig) => ({
  queryKey: [QKEY.AUCTION, "multiple", config],
  queryFn: async () => {
    let params: object = {
      expand: true,
      limit: config.limit,
      id_builder: config.id_builder,
    };

    switch (config.pageName) {
      case "all": {
        params = {
          ...params,
          onGoing: true,
          weekly: true,
        };
        break;
      }
      case "archive": {
        params = {
          ...params,
          onGoing: true,
          archive: true,
        };
        break;
      }
      case "barrels": {
        params = {
          ...params,
          onGoing: true,
          type_product: "barrel",
          type_auction: ID_AUCTION_TYPE.COLLECTION,
        };
        break;
      }
      case "charity": {
        params = {
          ...params,
          onGoing: true,
          charity: true,
        };
        break;
      }
      case "collections": {
        params = {
          ...params,
          onGoing: true,
          type_product: "lot",
          id_type_auction: ID_AUCTION_TYPE.COLLECTION,
        };
        break;
      }
      case "offers": {
        params = {
          ...params,
          bidded: true,
        };
        break;
      }
      case "private-sale": {
        params = {
          ...params,
          onGoing: true,
          id_type_auction: ID_AUCTION_TYPE.PRIVATE_SALE,
        };
        break;
      }
      case "single-lots": {
        params = {
          ...params,
          onGoing: true,
          type_product: "lot",
          type_auction: ID_AUCTION_TYPE.SINGLE_LOTS,
        };
        break;
      }
      case "shop": {
        params = {
          ...params,
          onGoing: true,
          id_type_auction: ID_AUCTION_TYPE.PERMANENT,
        };
        break;
      }
      case "en-primeur": {
        params = {
          ...params,
          onGoing: true,
          id_type_auction: ID_AUCTION_TYPE.EN_PRIMEUR,
        };
        break;
      }
      case "wishlist": {
        params = {
          ...params,
          onGoing: true,
          wishlist: true,
        };
        break;
      }
      default: {
        throw new Error("Unknown page name");
      }
    }
    const apiAuctions = await upfetch({
      url: "auctions/",
      params,
      schema: z.array(
        z.union([apiAuctionOfBarrelSchema, apiAuctionOfLotSchema]),
      ),
    });

    return apiAuctions.map((apiAuction) =>
      isAuctionOfBarrel(apiAuction)
        ? new AuctionOfBarrel(apiAuction)
        : new AuctionOfLot(apiAuction),
    );
  },
});

export const useAuctionsQuery = (
  config: UseAuctionsQueryConfig,
  options: UseQueryOptions<Auction[]> = {},
) => {
  const { accessToken } = useMyUser();

  return useQuery({
    queryKey: getAuctions(config).queryKey,
    queryFn: getAuctions(config).queryFn,
    enabled: !!accessToken,
    ...options,
  });
};

export const useAuctionsInfiniteQuery = (
  pageName: PageName,
  filters: SelectMultipleValues,
  search: string,
  sort: any,
  // limit = 20,
) => {
  const { accessToken } = useMyUser();

  return useInfiniteQuery({
    queryKey: ["store-auction", pageName, filters, search, sort],
    queryFn: async ({ pageParam = 1 }) => {
      const params: Record<string, any> = {
        ...filters,
        q: search,
        sort,
        expand: true,
        //limit: limit,
        onGoing: true,
        id_type_auction: ID_AUCTION_TYPE.PERMANENT,
        page: pageParam,
      };

      switch (pageName) {
        case "private-sale": {
          params["id_type_auction"] = ID_AUCTION_TYPE.PRIVATE_SALE;
          break;
        }
        case "shop": {
          params["id_type_auction"] = ID_AUCTION_TYPE.PERMANENT;
          break;
        }
        case "en-primeur": {
          params["id_type_auction"] = ID_AUCTION_TYPE.EN_PRIMEUR;
          break;
        }
        default: {
          throw new Error("Unknown page name");
        }
      }

      const apiAuctions = await upfetch({
        url: "v2_1/shop/",
        params: params,
        schema: z.object({
          items: z.array(
            z.union([apiAuctionOfBarrelSchema, apiAuctionOfLotSchema]),
          ),
          total: z.number(),
          page: z.number(),
          total_pages: z.number(),
        }),
      });

      return {
        ...apiAuctions,
        items: apiAuctions.items.map((apiAuction) =>
          isAuctionOfBarrel(apiAuction)
            ? new AuctionOfBarrel(apiAuction)
            : new AuctionOfLot(apiAuction),
        ),
      };
    },
    getNextPageParam: (lastPage) =>
      lastPage.page < lastPage.total_pages ? lastPage.page + 1 : null, // null makes `hasNextPage: false`
    enabled: !!accessToken,
    // ...options
  });
};

const getSingleAuction = <TAuction extends Auction>(
  id_auction: number,
  type_product: TypeProduct,
  onSuccess?: (auction: TAuction) => void,
) => ({
  queryKey: [QKEY.AUCTION, id_auction, type_product],

  queryFn: async () => {
    const apiAuctions = await upfetch({
      url: "auctions/",
      params: {
        expand: true,
        id_auction,
        type_product,
      },
      schema: z.array(
        z.union([apiAuctionOfBarrelSchema, apiAuctionOfLotSchema]),
      ),
    });
    const apiAuction = apiAuctions[0];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!apiAuction) throw new Error(`Auction ${id_auction} not found`);
    const auction = isAuctionOfLot(apiAuction)
      ? (new AuctionOfLot(apiAuction) as TAuction)
      : (new AuctionOfBarrel(apiAuction) as TAuction);
    onSuccess?.(auction);
    return auction;
  },
});

export const useSingleAuctionQuery = <TAuction extends Auction>(
  {
    id_auction,
    type_product,
  }: { id_auction: number; type_product: TypeProduct },
  options: UseQueryOptions<TAuction> & {
    initialData?: TAuction;
    polling?: boolean;
  } = {},
) => {
  const shouldPoll = options.polling ?? true;
  const queryResult = useQuery({
    initialDataUpdatedAt: options.initialData ? Date.now() + 500 : undefined,
    refetchOnMount: !options.initialData?.id ? "always" : false,
    queryKey: getSingleAuction<TAuction>(
      id_auction,
      type_product,
      handleSuccess,
    ).queryKey,
    queryFn: getSingleAuction<TAuction>(id_auction, type_product, handleSuccess)
      .queryFn,
    ...options,
  });

  const updateQueryCache = useUpdateQueryCache({ id_auction, type_product });

  const { refetchId, prevRefetchId, setPrevRefetchId } = useRefetchId({
    auction: queryResult.data,
  });

  const [outerMostRef, inView] = useInView({
    rootMargin: "600px 0px",
    root: document.getElementById(ID_IO_ROOT),
    initialInView: true,
  });

  // refetch only when the auction card is visible
  useUpdateEffect(() => {
    if (prevRefetchId === refetchId || !inView || !shouldPoll) return;
    setPrevRefetchId(refetchId);
    queryResult.refetch();
  }, [refetchId, inView, shouldPoll]);

  function handleSuccess(auction: TAuction) {
    updateQueryCache(auction);
  }

  return { ...queryResult, outerMostRef, inView };
};

function useRefetchId({ auction }: { auction: Auction | undefined }) {
  const refetchId = useCount(0);
  const [prevRefetchId, setPrevRefetchId] = useState(refetchId.value);

  const isPollingEnabled =
    !!auction &&
    ![ID_AUCTION_TYPE.PERMANENT, ID_AUCTION_TYPE.PRIVATE_SALE].includes(
      auction.type.id,
    );

  useCountdown({
    unit: "seconds",
    to: auction?.finish_data,
    onTick(remainingSec) {
      if (!isPollingEnabled) return;
      // auction finished
      if (remainingSec < 0) {
        return;
      }
      // <= 10 seconds refetch every 2 seconds
      if (remainingSec <= 10 && remainingSec % 2 === 0) {
        refetchId.increment();
        return;
      }
      // <= 1 minute refetch every 10 seconds
      if (remainingSec <= 30 && remainingSec % 5 === 0) {
        refetchId.increment();
        return;
      }
      // <= 1 minute refetch every 10 seconds
      if (remainingSec <= 60 && remainingSec % 10 === 0) {
        refetchId.increment();
        return;
      }
      // <= 5 minute refetch every 20 seconds
      if (remainingSec <= 60 * 5 && remainingSec % 20 === 0) {
        refetchId.increment();
        return;
      }
      // otherwise refetch every 1 minute
      if (remainingSec % 60 === 0) {
        refetchId.increment();
        return;
      }
    },
  });
  return { refetchId: refetchId.value, prevRefetchId, setPrevRefetchId };
}

function useUpdateQueryCache({
  id_auction,
  type_product,
}: {
  id_auction: number;
  type_product: TypeProduct;
}) {
  const queryClient = useQueryClient();

  return (auction: Auction) => {
    queryClient.setQueriesData(
      {
        queryKey: getAuctions({} as any).queryKey.slice(0, 2),
        // predicate: ({ queryKey }) => {
        //   return (
        //     isEqual(
        //       queryKey.slice(0, 2),
        //       getAuctions({ pageName: "all" }).queryKey,
        //     ) ||
        //     isEqual(
        //       queryKey.slice(0, 2),
        //       getAuctions({ pageName: "archive" }).queryKey,
        //     ) ||
        //     isEqual(
        //       queryKey.slice(0, 2),
        //       getAuctions({ pageName: "barrels" }).queryKey,
        //     ) ||
        //     isEqual(
        //       queryKey.slice(0, 2),
        //       getAuctions({ pageName: "charity" }).queryKey,
        //     ) ||
        //     isEqual(
        //       queryKey,
        //       getAuctions({ pageName: "collections" }).queryKey,
        //     ) ||
        //     isEqual(queryKey, getAuctions({ pageName: "offers" }).queryKey) ||
        //     isEqual(
        //       queryKey,
        //       getAuctions({ pageName: "private-sale" }).queryKey,
        //     ) ||
        //     isEqual(queryKey, getAuctions({ pageName: "shop" }).queryKey) ||
        //     isEqual(
        //       queryKey,
        //       getAuctions({ pageName: "single-lots" }).queryKey,
        //     ) ||
        //     isEqual(queryKey, getAuctions({ pageName: "wishlist" }).queryKey)
        //   );
        // },
      },
      (data: any) => {
        const foundIndex = findIndex(data, {
          id_auction,
          type_product,
        });
        if (foundIndex === -1) return data;
        const newData = [...data];
        newData[foundIndex] = auction;
        return newData;
      },
    );
  };
}

const AuctionsFiltersResponseSchema = z.record(
  z.string(),
  z.object({
    label: z.string(),
    filters: z.array(
      z.object({
        label: z.union([z.string(), z.number()]),
        total: z.number(),
      }),
    ),
  }),
);

export type AuctionsFiltersResponseSchema = z.infer<
  typeof AuctionsFiltersResponseSchema
>;

export const useAuctionsFilters = (
  pageName: PageName,
  availableFilters: SelectMultipleValues | undefined = {},
) => {
  return useQuery({
    queryKey: ["auctions-filters", pageName, availableFilters],
    queryFn: async () => {
      const params: Record<string, any> = {
        ...availableFilters,
        expand: true,
        onGoing: true,
        id_type_auction: ID_AUCTION_TYPE.PERMANENT,
      };

      switch (pageName) {
        case "private-sale": {
          params["id_type_auction"] = ID_AUCTION_TYPE.PRIVATE_SALE;
          break;
        }
        case "shop": {
          params["id_type_auction"] = ID_AUCTION_TYPE.PERMANENT;
          break;
        }
        case "en-primeur": {
          params["id_type_auction"] = ID_AUCTION_TYPE.EN_PRIMEUR;
          break;
        }
        default: {
          throw new Error("Unknown page name");
        }
      }
      return await upfetch({
        url: "v2_1/shop/filters",
        params,
        schema: AuctionsFiltersResponseSchema,
      });
    },
  });
};
