import { Redirect, useParams } from "react-router-dom";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { useSingleAuctionQuery } from "src/app/api/auctions";
import { path } from "src/app/routes/path";
import { TypeProduct } from "src/app/const";
import { isAuctionOfBarrel, isAuctionOfLot } from "src/app/models/auctions";
import { getAuctionPageTitle } from "./utils";
import { ContainerDesktop } from "./container-desktop";
import { Form } from "./form";
import { Why } from "./why";
import { Heading } from "./heading";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { ContainerMobile } from "./container-mobile";
import { CollectionOfLotSlider } from "./collection-of-lot-slider";
import { ShadcnCarouselApi } from "src/app/components/shadcn-carousel";
import { useState } from "react";
import { CollectionOfLotAccordion } from "./collection-of-lot-accordion";
import { AuctionFeatures } from "./auction-features";
import { AboutTheEstate } from "./about-the-estate";
import { YouMightAlsoLike } from "./you-might-also-like";
import { useWindowSize } from "react-use";
import { SingleLotImage } from "./single-lot-image";
import { ProductDetails } from "./product-details";
import { BarrelInput } from "./barrel-input";
import { MoreProducts } from "./more-products";
import { useSidebarStatus } from "src/app/layout.private/sidebar-menu/useSidebarStatus";
import {
  CardCtxProvider,
  useCardCtx,
} from "src/app/components/auction-card/card-context";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";

// TODO: fix all "as" type casts

type Params = {
  type_product: TypeProduct;
  id_auction_and_auction_name: string;
};

const PageSalesWithoutContext = ({
  Container,
  isThereLittleSpace,
}: {
  Container: typeof ContainerMobile | typeof ContainerDesktop;
  isThereLittleSpace: boolean;
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [openSlideIndex, setOpenSlideIndex] = useState<number | null>(null);
  const [carouselApi, setCarouselApi] = useState<ShadcnCarouselApi>();
  const cardCtx = useCardCtx();

  const isBarrel = isAuctionOfBarrel(cardCtx.auction);
  const isCollection =
    isAuctionOfLot(cardCtx.auction) &&
    (cardCtx.auction.isOfLotCollection ||
      (cardCtx.auction.lot?.components.length || 0) > 1); // handle PS and shop case
  const isSingleLot = isAuctionOfLot(cardCtx.auction) && !isCollection;

  return (
    <>
      <Container
        isCollection={isCollection}
        isBarrel={isBarrel}
        isSingleLot={isSingleLot}
        heading={<Heading auction={cardCtx.auction} />}
        auctionFeatures={<AuctionFeatures auction={cardCtx.auction} />}
        singleLotImage={
          !!isSingleLot && (
            <SingleLotImage auction={cardCtx.auction as AuctionOfLot} />
          )
        }
        barrelInput={
          !!isBarrel && (
            <BarrelInput
              auction={cardCtx.auction as AuctionOfBarrel}
              isThereLittleSpace={isThereLittleSpace}
              quantity={cardCtx.quantity || cardCtx.auction.initialQuantity}
              onChangeQuantity={cardCtx.setQuantity}
            />
          )
        }
        collectionOfLotSlider={
          !!isCollection && (
            <CollectionOfLotSlider
              setCarouselApi={setCarouselApi}
              carouselApi={carouselApi}
              auction={cardCtx.auction as AuctionOfLot}
              activeSlideIndex={activeSlideIndex}
              onChangeActiveSlideIndex={(index) => {
                setOpenSlideIndex(index);
                setActiveSlideIndex(index);
              }}
            />
          )
        }
        collectionOfLotAccordion={
          !!isCollection && (
            <CollectionOfLotAccordion
              activeSlideIndex={activeSlideIndex}
              onChangeActiveSlideIndex={(index) => {
                setActiveSlideIndex(index);
                carouselApi?.scrollTo(index);
              }}
              onChangeOpenSlideIndex={(index) => {
                setOpenSlideIndex(index);
              }}
              openSlideIndex={openSlideIndex}
              auction={cardCtx.auction as AuctionOfLot}
            />
          )
        }
        productDetails={
          isSingleLot ? (
            <ProductDetails
              product={(cardCtx.auction as AuctionOfLot).lot?.components[0]}
            />
          ) : isAuctionOfBarrel(cardCtx.auction) ? (
            <ProductDetails
              product={(cardCtx.auction as AuctionOfBarrel).barrel}
            />
          ) : null
        }
        aboutTheEstate={
          <AboutTheEstate
            builder={
              isBarrel
                ? (cardCtx.auction as AuctionOfBarrel).barrel.builder
                : (cardCtx.auction as AuctionOfLot).lot?.components[
                    activeSlideIndex
                  ]?.builder
            }
          />
        }
        form={<Form />}
        why={<Why auction={cardCtx.auction} />}
        moreProducts={<MoreProducts auction={cardCtx.auction} />}
        youMightAlsoLike={<YouMightAlsoLike auction={cardCtx.auction} />}
      />
    </>
  );
};

export function PageSales() {
  const isMobile = useIsMobile();
  const type_product = useParams<Params>().type_product;
  const id_auction =
    +useParams<Params>().id_auction_and_auction_name.split("-")[0];
  const sidebar = useSidebarStatus();
  const windowSize = useWindowSize();

  const {
    isLoading,
    data: auction,
    isError,
  } = useSingleAuctionQuery(
    {
      id_auction,
      type_product,
    },
    { polling: false }, // polling is done in the card ctx
  );

  usePageTitle(getAuctionPageTitle(auction));

  if ((!isLoading && !auction) || isError) {
    return <Redirect to={path["404"]()} />;
  }

  const isThereLittleSpace =
    isMobile || (sidebar.isPinned && windowSize.width <= 1140);

  const Container = isThereLittleSpace ? ContainerMobile : ContainerDesktop;

  if (!auction) {
    return <Container empty />;
  }

  return (
    <CardCtxProvider initialAuction={auction}>
      <PageSalesWithoutContext
        Container={Container}
        isThereLittleSpace={isThereLittleSpace}
      />
    </CardCtxProvider>
  );
}
