/* eslint-disable no-restricted-imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import parse from "html-react-parser";
import { TableNoDataBox } from "../common/TableNoDataBox";
import { TableAuctionStatus } from "./TableAuctionStatus";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Skeleton } from "src/app/components/skeleton";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
  {
    id: "id_auction",
    numeric: false,
    disablePadding: false,
    label: "Id",
    colClass: "csst-id-col",
  },
  {
    id: "description_auction",
    numeric: true,
    disablePadding: false,
    label: "Name",
    colClass: "csst-requestdate-col",
  },
  {
    id: "quantity_resell",
    numeric: false,
    disablePadding: false,
    label: "Qty Resell",
    colClass: "csst-tracking-col",
  },
  {
    id: "quantity_pending_sale",
    numeric: false,
    disablePadding: false,
    label: "Qty Pending",
    colClass: "csst-qtyshipped-col",
  },
  {
    id: "quantity_sold",
    numeric: false,
    disablePadding: false,
    label: "Qty Sold",
    colClass: "csst-status-col",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    colClass: "csst-status-col",
  },
];

const headRowsMobile = [
  {
    id: "id_auction",
    numeric: false,
    disablePadding: false,
    label: "Id",
    colClass: "csst-id-col",
  },
  {
    id: "description_auction",
    numeric: true,
    disablePadding: false,
    label: "Name",
    colClass: "csst-requestdate-col",
  },
  {
    id: "quantity_resell",
    numeric: false,
    disablePadding: false,
    label: "Qty Resell",
    colClass: "csst-tracking-col",
  },
  {
    id: "quantity_pending_sale",
    numeric: false,
    disablePadding: false,
    label: "Qty Pending",
    colClass: "csst-qtyshipped-col",
  },
  {
    id: "quantity_sold",
    numeric: false,
    disablePadding: false,
    label: "Qty Sold",
    colClass: "csst-status-col",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    colClass: "csst-status-col",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const isMobile = useIsMobile();

  var headRowsNew = isMobile ? headRowsMobile : headRows;

  return (
    <TableHead>
      <TableRow>
        {headRowsNew.map((row) => (
          <TableCell
            key={row.id}
            //align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
            className={row.colClass ? row.colClass : ""}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {parse(row.label)}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: "auto",
  },
}));

export default function CellarResellStatusSlideTable(props) {
  const classes = useStyles();
  const data = props.data && props.data.length > 0 ? props.data : [];
  const isFiltered = props.isFiltered;
  const dataFiltered =
    props.dataFiltered && props.dataFiltered.length > 0
      ? props.dataFiltered
      : [];

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const isMobile = useIsMobile();

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  }

  function handleClick(event, name) {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  var search = isFiltered ? dataFiltered.length : data.length;
  const emptyRows = search === 0 ? 1 : 0;
  const heightRows =
    rowsPerPage - Math.min(rowsPerPage, search - page * rowsPerPage);

  var skeletonRows = [];
  for (let i = 0; i < rowsPerPage; i++) {
    skeletonRows.push({
      skeleton: "skeleton",
    });
  }

  const ContTableRow = ({ row, isItemSelected }) => {
    return (
      <TableRow
        hover
        onClick={(event) => handleClick(event, row.name)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.name}
        selected={isItemSelected}
      >
        {isMobile ? (
          <>
            <TableCell className="phy-data-col">
              <div className="phy-data-table">
                <div className="phy-data-table-row">
                  <div className="phy-data-col-label">Id Auction</div>
                  <div className="phy-data-col-values">{row.id_auction}</div>
                </div>
                <div className="phy-data-table-row">
                  <div className="phy-data-col-label">Name</div>
                  <div className="phy-data-col-values">
                    {row.description_auction}
                  </div>
                </div>
                <div className="phy-data-table-row">
                  <div className="phy-data-col-label">Qty Resell</div>
                  <div className="phy-data-col-values">
                    {row.quantity_resell}
                  </div>
                </div>
                <div className="phy-data-table-row">
                  <div className="phy-data-col-label">Qty Pending</div>
                  <div className="phy-data-col-values">
                    {row.quantity_pending_sale}
                  </div>
                </div>
                <div className="phy-data-table-row">
                  <div className="phy-data-col-label">Qty Sold</div>
                  <div className="phy-data-col-values">{row.quantity_sold}</div>
                </div>
                <div className="phy-data-table-row">
                  <div className="phy-data-col-label">Status</div>
                  <div className="phy-data-col-values">
                    <TableAuctionStatus
                      closed={row.closed > 0 ? true : false}
                      status={row.stato}
                      finishData={row.finish_data}
                    />
                  </div>
                </div>
              </div>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell className="csst-id-col">
              <span className="csst-cell-text">{row.id_auction}</span>
            </TableCell>
            <TableCell className="csst-requestdate-col">
              <span className="csst-cell-text">{row.description_auction}</span>
            </TableCell>
            <TableCell className="csst-shippingdate-col">
              <span className="csst-cell-text">{row.quantity_resell}</span>
            </TableCell>
            <TableCell className="csst-tracking-col">
              <span className="csst-cell-text">
                {row.quantity_pending_sale}
              </span>
            </TableCell>
            <TableCell className="csst-qtyshipped-col">
              <span className="csst-cell-text">{row.quantity_sold}</span>
            </TableCell>
            <TableCell className="csst-qtyshipped-col">
              <span className="csst-cell-text">
                <TableAuctionStatus
                  closed={row.closed > 0 ? true : false}
                  status={row.stato}
                  finishData={row.finish_data}
                />
              </span>
            </TableCell>
          </>
        )}
      </TableRow>
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
            {isFiltered ? (
              <TableBody>
                {stableSort(dataFiltered, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    var statusCode = 0;
                    if (parseInt(row.is_winner) === 0) {
                      if (
                        parseInt(row.id_type_auction) === 1 ||
                        parseInt(row.id_type_auction) === 4
                      ) {
                        if (row.is_winning === 0) {
                          // Lost Bid
                          statusCode = 2;
                        } else if (
                          row.is_winning === 1 &&
                          row.id_auction_participant_winning ===
                            row.id_auction_participant
                        ) {
                          // Highest Bidder
                          statusCode = 3;
                        } else {
                          // Lost Bid
                          statusCode = 2;
                        }
                      } else {
                        // Pending
                        statusCode = 0;
                      }
                    } else if (row.is_winner == -1) {
                      // Unsuccessful
                      statusCode = -1;
                    } else {
                      // Winner
                      statusCode = 1;
                    }

                    return (
                      <ContTableRow
                        key={"kcjubhod" + index}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        statusCode={statusCode}
                      />
                    );
                  })}
                {emptyRows === 0 && heightRows > 0 && (
                  <TableRow style={{ height: 49 * heightRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * heightRows }}>
                    <TableCell colSpan={9} className="fullwidth-cell">
                      <TableNoDataBox
                        title={"You don't have any Offers"}
                        content={""}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            ) : props.loadFetch ? (
              <TableBody>
                {skeletonRows.map((row, index) => {
                  var fakeRows = isMobile ? headRowsMobile : headRows;
                  return (
                    <TableRow key={"piwueh" + index}>
                      {fakeRows.map((item, key) => {
                        return (
                          <TableCell key={key + "piwueh" + index}>
                            <Skeleton.Rect variant="text" height={50} />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    var statusCode = 0;
                    if (parseInt(row.is_winner) === 0) {
                      if (
                        parseInt(row.id_type_auction) === 1 ||
                        parseInt(row.id_type_auction) === 4
                      ) {
                        if (row.is_winning === 0) {
                          // Lost Bid
                          statusCode = 2;
                        } else if (
                          row.is_winning === 1 &&
                          row.id_auction_participant_winning ===
                            row.id_auction_participant
                        ) {
                          // Highest Bidder
                          statusCode = 3;
                        } else {
                          // Lost Bid
                          statusCode = 2;
                        }
                      } else {
                        // Pending
                        statusCode = 0;
                      }
                    } else if (row.is_winner == -1) {
                      // Unsuccessful
                      statusCode = -1;
                    } else {
                      // Winner
                      statusCode = 1;
                    }

                    return (
                      <ContTableRow
                        key={"stiop" + index}
                        row={row}
                        isItemSelected={isItemSelected}
                        labelId={labelId}
                        statusCode={statusCode}
                      />
                    );
                  })}
                {emptyRows === 0 && heightRows > 0 && (
                  <TableRow style={{ height: 49 * heightRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * heightRows }}>
                    <TableCell colSpan={9} className="fullwidth-cell">
                      <TableNoDataBox
                        title={"You don't have any Offers"}
                        content={""}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
