import { z } from "zod";
import { Country, apiCountrySchema } from "./Country";

export const addressSchemaV1 = z.object({
  id: z.number(),
  id_address: z.number(),
  phone: z.string(),
  vat: z.string(),
  full_name: z.string(),
  company_name: z.string().nullable(),
  deleted: z.boolean(),
  confirmed: z.boolean(),
  selected: z.boolean(),
  type: z.union([z.literal("invoice"), z.literal("shipping")]),
  additional_information: z.string(),
  created_date: z.string(),
  address: z.object({
    id: z.number(),
    id_address: z.number(),
    address_line_1: z.string(),
    address_line_2: z.string(),
    city: z.string(),
    region: z.string(),
    postal_code: z.string(),
    /**
     * !unsafe: full_text, lat, lng may be incorrect
     */
    // full_text: z.string(),
    // lat: z.string(),
    // lng: z.string(),
    created_date: z.string(),
    address_complete_without_name: z.string(),
    country: apiCountrySchema,
  }),
  address_complete: z.string(),
  active: z.boolean(),
  totalExpedition: z.number(),
});

type ApiAddressV1 = z.infer<typeof addressSchemaV1>;
export type AddressType = ApiAddressV1["type"];

export class AddressV1 {
  id: number;
  id_address: number;
  phone: string;
  vat: string;
  full_name: string;
  company_name: string | null;
  deleted: boolean;
  confirmed: boolean;
  selected: boolean;
  type: AddressType;
  additional_information: string;
  created_date: string;
  address: ApiAddressV1["address"];
  address_complete: string;
  active: boolean;
  totalExpedition: number;

  constructor(apiAddress: ApiAddressV1) {
    this.id = apiAddress.id;
    this.id_address = apiAddress.id_address;
    this.phone = apiAddress.phone;
    this.vat = apiAddress.vat;
    this.full_name = apiAddress.full_name;
    this.company_name = apiAddress.company_name;
    this.deleted = apiAddress.deleted;
    this.confirmed = apiAddress.confirmed;
    this.selected = apiAddress.selected;
    this.type = apiAddress.type;
    this.additional_information = apiAddress.additional_information;
    this.created_date = apiAddress.created_date;
    this.address = {
      id: apiAddress.address.id,
      id_address: apiAddress.address.id_address,
      address_line_1: apiAddress.address.address_line_1,
      address_line_2: apiAddress.address.address_line_2,
      city: apiAddress.address.city,
      region: apiAddress.address.region,
      postal_code: apiAddress.address.postal_code,
      // full_text: apiAddress.address.full_text,
      // lat: apiAddress.address.lat,
      // lng: apiAddress.address.lng,
      created_date: apiAddress.address.created_date,
      address_complete_without_name:
        apiAddress.address.address_complete_without_name,
      country: new Country(apiAddress.address.country),
    };
    this.address_complete = apiAddress.address_complete;
    this.active = apiAddress.active;
    this.totalExpedition = apiAddress.totalExpedition;
  }
}
