import { FetcherOptions, createFetcher, isResponseError } from "up-fetch";
import { STORAGE_KEY } from "../const";
import { ZodError, ZodSchema, ZodTypeDef } from "zod";
import { path } from "../routes/path";
import { env } from "src/env";
import * as Sentry from "@sentry/react";

export const upfetch = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: `${env.REACT_APP_API_URL_NEW}v2/`,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      onError(error) {
        if (isResponseError(error) && error.response.status === 499) {
          window.location.pathname = path.logout();
        }
      },
    };
  }),
);

export const upfetchV1 = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: env.REACT_APP_API_URL_NEW,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      params: accessToken ? { accessToken } : {},
      onError(error) {
        if (isResponseError(error) && error.response.status === 499) {
          window.location.pathname = path.logout();
        }
      },
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      throwWhen: async (res) => {
        try {
          const { code } = await res.clone().json();
          return code !== 200;
        } catch (e) {
          return true;
        }
      },
    };
  }),
);

export const upfetchV1New = withZod(
  createFetcher(() => {
    const accessToken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    return {
      baseUrl: env.REACT_APP_API_URL_NEW,
      headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
      onError(error) {
        if (isResponseError(error) && error.response.status === 499) {
          window.location.pathname = path.logout();
        }
      },
      parseResponse: (response, options, defaultParser) =>
        defaultParser(response).then((data) => data?.result ?? data),
      throwWhen: async (res) => {
        try {
          const { code } = await res.clone().json();
          return code !== 200;
        } catch (e) {
          return true;
        }
      },
    };
  }),
);

function withZod<TD = any>(
  fetcher: (options?: FetcherOptions<TD>) => Promise<TD>,
) {
  async function fetcherWithZod<D = TD, I = D, O = I>(
    options: FetcherOptions<D> & { schema?: ZodSchema<I, ZodTypeDef, O> },
  ) {
    const data = await fetcher(options as any);
    if (env.isDev || !env.isProd) {
      try {
        return options.schema?.parse(data) || (data as I);
      } catch (error) {
        if (error instanceof ZodError) {
          console.error("Validation error:", error.errors);
        }
      }
      return data as I;
    } else {
      if (!options.schema) return data as I;
      const result = options.schema.safeParse(data);
      if (!result.success) {
        console.error(result.error);
        Sentry.captureException(result.error);
        return data as I;
      }
      return result.data;
    }
  }

  return fetcherWithZod;
}
