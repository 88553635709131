import "swiper/swiper-bundle.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper";
import styles from "./SliderLots.module.scss";
import { useInView } from "react-intersection-observer";
import { Img } from "src/app/components/img";
import { ID_IO_ROOT } from "src/app/const";

const SliderLots = ({
  gallery,
  parentRef,
  activeSlideIndex,
  totalSlidesCount,
  onChangeSlideIndex,
  hasBackground,
  height = undefined,
}) => {
  const handlePrevClick = () => {
    const nextIndex = Math.max(+activeSlideIndex - 1, 0);
    onChangeSlideIndex(nextIndex);
  };

  const handleNextClick = () => {
    const nextIndex = Math.min(+activeSlideIndex + 1, totalSlidesCount - 1);
    onChangeSlideIndex(nextIndex);
  };

  const { ref, inView } = useInView({
    rootMargin: "600px 0px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  if (!inView) {
    return <div ref={ref} />;
  }

  return (
    <div
      className={`${styles.SliderLots} swiperCollections`}
      style={{ maxWidth: "100%", padding: "24px", margin: 0, height: height }}
    >
      <Swiper
        ref={parentRef}
        allowTouchMove={false}
        tabIndex={activeSlideIndex}
        navigation={{
          prevEl: totalSlidesCount > 1 ? ".swiper-button-prev" : "",
          nextEl: totalSlidesCount > 1 ? ".swiper-button-next" : "",
        }}
        modules={[EffectFade, Navigation]}
      >
        {gallery.flat().map((photo, index) => {
          return (
            <SwiperSlide key={`slide-${index}`}>
              <div className={`${styles.image_loader}`}>
                <Img
                  className="mw-100"
                  style={{ objectFit: "contain", maxHeight: "300px" }}
                  src={photo}
                  alt="slide wine bottle"
                  lazyLoad={false}
                />
              </div>
            </SwiperSlide>
          );
        })}

        {totalSlidesCount > 1 && (
          <div
            style={{ filter: hasBackground ? "brightness(100)" : "" }}
            className="swiper-button-prev"
            onClick={handlePrevClick}
          />
        )}
        {totalSlidesCount > 1 && +activeSlideIndex < totalSlidesCount - 1 && (
          <div
            style={{ filter: hasBackground ? "brightness(100)" : "" }}
            className="swiper-button-next"
            onClick={handleNextClick}
          />
        )}
      </Swiper>
    </div>
  );
};

export default SliderLots;
