import { omit } from "lodash";
import { useAuctionsFilters } from "src/app/api/auctions";
import {
  SelectMultipleOption,
  SelectMultipleValues,
} from "src/app/components/form-elements/select-multiple/types";
import { PageName } from "src/app/const";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";

export const useFilters = (pageName: PageName) => {
  const [queryParams, setQueryParams] = useTypedSearchParams({
    schema: z.record(
      z.string(),
      z
        .union([
          z.string(), // this is required to also support 'search' and 'sort' which are removed later on
          z.number(), // this is required to restore scroll position to auction card via '?id={collectionID}'
          z.array(z.union([z.string(), z.number(), z.boolean()])),
        ])
        .optional(),
    ),
  });

  // TODO: find a better way to only include actual filter which doesn't imply waiting for the filters query data
  const rawFilters: SelectMultipleValues = omit(queryParams, [
    "search",
    "sort",
    "id",
  ]) as SelectMultipleValues;
  const filtersQuery = useAuctionsFilters(pageName, rawFilters);

  const isLoadingFilters = filtersQuery.isLoading;

  const filterOptions: Record<string, SelectMultipleOption[]> =
    filtersQuery.data
      ? Object.entries(filtersQuery.data).reduce(
          (allOptions, [serializedGroupName, groupOptions]) => {
            allOptions[serializedGroupName] = groupOptions.filters.map(
              (filter) => ({
                label: filter.label,
                count: filter.total,
                value: filter.label,
                groupName: groupOptions.label,
                serializedGroupName,
              }),
            );

            return allOptions;
          },
          {} as Record<string, SelectMultipleOption[]>,
        )
      : {};

  // omit "search", "sort" from filters and ensure valid keys AND values
  const allowedFiltersGroupNames = Object.keys(filtersQuery.data || {});
  const filtersValues: SelectMultipleValues = Object.keys(filterOptions).length
    ? Object.entries(queryParams).reduce(
        (filters, [serializedGroupName, values]) => {
          // Actual filters are always parsed as arrays
          if (
            !Array.isArray(values) ||
            !allowedFiltersGroupNames.includes(serializedGroupName)
          ) {
            return filters;
          }

          const allowedFiltersValues = values.filter((value) =>
            filterOptions[serializedGroupName].find((el) => el.value === value),
          );
          if (allowedFiltersValues.length) {
            filters[serializedGroupName] = allowedFiltersValues;
          }

          return filters;
        },
        {} as SelectMultipleValues,
      )
    : {};

  return {
    rawValues: rawFilters,
    values: filtersValues,
    isLoadingFilters: isLoadingFilters,
    options: filterOptions,
    setFilters: setQueryParams,
    search: queryParams.search as string,
  } as const;
};
