import styles from "./index.module.css";
import { noop } from "lodash";
import { useInView } from "react-intersection-observer";
import {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  CSSProperties,
  ReactNode,
} from "react";
import { useDebounceFn } from "src/app/hooks/useDebounceFn";
import { ID_IO_ROOT } from "src/app/const";
import { toPx } from "src/app/utils/toPx";
import { cn } from "src/app/utils/cn";
import { Img } from "../img";
import { useThrottleFn } from "src/app/hooks/useThrottleFn";
import { IconPrev } from "./icon-prev";
import { IconNext } from "./icon-next";
import useResizeObserver from "use-resize-observer";

type Props = {
  aspectRatio?: [number, number];
  className?: string;
  controlled?: boolean;
  iconMargin?: number;
  images: string[];
  maxHeight?: string | number;
  minHeight?: string | number;
  slideStyle?: CSSProperties;
  style?: CSSProperties;

  objectFit?: CSSProperties["objectFit"];
} & (
  | { controlled?: false; index?: never; onChangeIndex?: never }
  | { controlled: true; index: number; onChangeIndex: (index: number) => void }
);

export function Carousel({
  aspectRatio = [1, 1],
  className = "",
  controlled = false,
  iconMargin = 16,
  images = [],
  maxHeight,
  minHeight,
  slideStyle = {},
  style = {},
  index = 0,
  onChangeIndex = noop,
  objectFit = "contain",
}: Props) {
  const [_index, _setIndex] = useState(0);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const activeIndex = controlled ? index : _index;

  function handleChangeActiveIndex(activeIndex: number) {
    _setIndex(activeIndex);
    onChangeIndex(activeIndex);
  }

  const { ref, inView } = useInView({
    rootMargin: "600px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  if (!images.length) return null;

  return (
    <div
      className={className}
      style={{
        position: "relative",
        aspectRatio: aspectRatio.join(" / "),
        maxHeight: toPx(maxHeight),
        minHeight: toPx(minHeight),
        margin: "auto",
        ...style,
      }}
      ref={ref}
    >
      {!!activeIndex && images.length > 1 && (
        <button
          className={cn(styles.iconBtn, styles.prev)}
          style={{ marginLeft: toPx(iconMargin) }}
          onClick={() => scrollToIndex(wrapperRef, activeIndex - 1)}
        >
          <IconPrev />
        </button>
      )}

      {activeIndex < images.length - 1 && images.length > 1 && (
        <button
          className={cn(styles.iconBtn, styles.next)}
          style={{ marginRight: toPx(iconMargin) }}
          onClick={() => scrollToIndex(wrapperRef, activeIndex + 1)}
        >
          <IconNext />
        </button>
      )}

      <SlidesWrapper
        wrapperRef={wrapperRef}
        index={activeIndex}
        onIndexChange={handleChangeActiveIndex}
      >
        {images.map((image, i) => (
          <div key={image + i} className={styles.slide} style={slideStyle}>
            <div className={styles.imageWrapper}>
              {!!inView && (
                <Img src={image} alt="" style={{ objectFit }} lazyLoad />
              )}
            </div>
          </div>
        ))}
      </SlidesWrapper>
    </div>
  );
}

type SlideWrapperProps = {
  index: number;
  children: ReactNode;
  onIndexChange: (index: number) => void;
  wrapperRef: React.MutableRefObject<HTMLDivElement | null>;
};

const SlidesWrapper = ({
  index = 0,
  children = null,
  onIndexChange,
  wrapperRef,
}: SlideWrapperProps) => {
  // rerender when resizing the window (or the carousel)
  useResizeObserver({ ref: wrapperRef });
  // used to make sure not to trigger a new scroll when the index changes
  const isScrollingRef = useRef(false);

  useLayoutEffect(() => {
    setTimeout(() => scrollToIndex(wrapperRef, 0, { behavior: "instant" }));
  }, [wrapperRef]);

  useEffect(() => {
    setTimeout(() => {
      if (isScrollingRef.current) return;
      scrollToIndex(wrapperRef, index);
    });
  }, [index, wrapperRef]);

  const handleScrollEnd = useDebounceFn(() => {
    isScrollingRef.current = false;
  }, 150);

  const handleScroll = useThrottleFn(() => {
    isScrollingRef.current = true;
    const carouselEl = wrapperRef.current;
    const wrapperEl = wrapperRef.current;
    if (!carouselEl || !wrapperEl) return;
    const newIndex = Math.round(carouselEl.scrollLeft / wrapperEl.offsetWidth);
    newIndex !== index && onIndexChange(newIndex);
  }, 100);

  return (
    <div
      ref={wrapperRef}
      onScroll={() => {
        handleScroll();
        handleScrollEnd();
      }}
      className={styles.wrapper}
    >
      {children}
    </div>
  );
};

function scrollToIndex(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  index: number,
  options?: Pick<ScrollToOptions, "behavior">,
) {
  ref.current?.scrollTo({
    left: ref.current.offsetWidth * Math.max(0, index),
    behavior: options?.behavior ?? "auto",
  });
}
