import "./styles-to-refactor.css";
import styles from "./left-side-subtitle-to-refactor.module.css";
import { Button } from "../../button";
import { gaEventConfig } from "src/app/models/GaEvent";
import { path } from "src/app/routes/path";
import { useHistory } from "react-router-dom";
import { useTypedSearchParams } from "src/app/hooks/useTypedSearchParams";
import { z } from "zod";
import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import { Experience } from "src/app/models/Experience";

type Props = {
  auction: Auction;
  experience?: Experience | null;
  additionalClasses?: string;
  addClass?: string;
};

export default function LeftSideSubtitleToRefactor({
  additionalClasses = "",
  addClass,
  auction,
  experience,
}: Props) {
  const history = useHistory();
  const [, setSearchParams] = useTypedSearchParams({
    schema: z.object({ id: z.number().optional() }),
  });

  return (
    <Button
      onClick={() => {
        setSearchParams({ id: auction.id }); // required for "back" button
        history.push(path.sales.value(auction));
      }}
      label="Discover More"
      variant="ghost"
      className={`${styles.root} ${
        experience?.images.length ? styles.experience : ""
      } ${additionalClasses} ${
        isAuctionOfBarrel(auction) || experience?.images.length ? addClass : ""
      }`}
      style={{
        marginLeft: `${isAuctionOfBarrel(auction) ? "5px" : 0}`,
        paddingBottom: "4px",
        paddingLeft: "0px",
        fontSize: "14px",
        lineHeight: "1rem",
        zIndex: "1",
      }}
      gaEventConfig={gaEventConfig.viewAuctionDetails(auction)}
    />
  );
}
