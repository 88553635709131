import React, {useState, useEffect} from "react";

export function SuccessPopup({title, subTitle, hidePopup}){
    const [activePopupClass, setPopupActive] = useState('');
    const [activeCount, setActiveCount] = useState(0);

    useEffect(() => {
        if(activeCount === 0){
            setTimeout(function(){
                setPopupActive('active');
            }, 100);
            setActiveCount(activeCount+1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeActive = () => {
        setPopupActive('');
        setTimeout(function(){
            hidePopup();
        }, 400);
    }

    return(
        <>
            <div className={`confirm-popup-overlay popup-overlay  ${activePopupClass && `active`}`}></div>
            <div className={`confirm-popup  ${activePopupClass && `active`}`}>
                <div className="confirm-popup-close-cta" onClick={removeActive}>Close</div>
                <div className="confirm-popup-inner">
                    <div className="confirm-popup-icon confirm-popup-icon-success"></div>
                    <span className="confirm-popup-title">{title}</span>
                    <span className="confirm-popup-subtitle">{subTitle}</span>
                    <div className="confirm-popup-actions">
                        <button className="btn btn-primary" onClick={removeActive}>OK</button>
                    </div>
                </div>
            </div>
        </>
    )
}