import styles from "./container-desktop.module.css";
import { ReactNode } from "react";
import { BackLink } from "./back-link";

type Props =
  | {
      authenticatedByCrurated?: ReactNode;
      isCollection: boolean;
      isBarrel: boolean;
      isSingleLot: boolean;
      heading: ReactNode;
      form: ReactNode;
      why: ReactNode;
      collectionOfLotSlider: ReactNode;
      collectionOfLotAccordion: ReactNode;
      auctionFeatures: ReactNode;
      aboutTheEstate: ReactNode;
      moreProducts: ReactNode;
      youMightAlsoLike: ReactNode;
      singleLotImage: ReactNode;
      productDetails: ReactNode;
      barrelInput: ReactNode;
    }
  | {
      empty: true;
    };

export const ContainerDesktop = (props: Props) => {
  if ("empty" in props) {
    return (
      <section className={styles.root}>
        <div style={{ marginBottom: "0.75rem" }}>
          <BackLink />
        </div>
      </section>
    );
  }

  return (
    <section className={styles.root}>
      <div style={{ marginBottom: "0.75rem" }}>
        <BackLink />
      </div>

      <div className={styles.content}>
        <div className={styles.left}>
          {!!props.isCollection && (
            <div>
              <div style={{ marginBottom: "20px" }}>{props.heading}</div>
              <div style={{ marginBottom: "30px" }}>
                {props.auctionFeatures}
              </div>
              <div>{props.collectionOfLotSlider}</div>

              <div style={{ marginBottom: "50px" }}>
                {props.collectionOfLotAccordion}
              </div>
            </div>
          )}
          {(!!props.isSingleLot || !!props.isBarrel) && (
            <div
              style={{ marginBottom: "20px" }}
              className={styles.notCollection}
            >
              <div>
                <div style={{ marginBottom: "20px" }}>{props.heading}</div>
                <div style={{ marginBottom: "30px" }}>
                  {props.auctionFeatures}
                </div>
              </div>
              <div>{props.singleLotImage || props.barrelInput}</div>
            </div>
          )}
          {!!props.productDetails && (
            <div style={{ marginBottom: "42px" }}>{props.productDetails}</div>
          )}
          <div style={{ marginBottom: "42px" }}>{props.aboutTheEstate}</div>
          <hr className={styles.hr} />
        </div>
        <div className={styles.right}>
          <div className={styles.rightInner}>
            {props.form} {props.why}
          </div>
        </div>
        <div style={{ marginBottom: "0px" }} className={styles.bottom}>
          {props.moreProducts}
        </div>
        <div style={{ marginBottom: "60px" }} className={styles.bottom}>
          {props.youMightAlsoLike}
        </div>
      </div>
    </section>
  );
};
