import { useState } from "react";
import { Tooltip } from "src/app/components/tooltip";
import { FaClipboard } from "react-icons/fa";

export const CopyToClipboard = (props) => {
  const TextToCopy = props.TextToCopy;
  const [toCopy, SetToCopy] = useState(true);

  const handlingCopy = () => {
    navigator.clipboard.writeText(TextToCopy);
    SetToCopy(false);
  };

  return (
    <Tooltip
      position="bottom"
      content={toCopy ? "Copy to Clip Board" : "Copied!"}
    >
      <span
        className="label label-lg label-inline warehouseStatus copyToClipboard bg-primary"
        onClick={() => {
          handlingCopy();
        }}
      >
        <FaClipboard style={{ color: "white" }} />
      </span>
    </Tooltip>
  );
};
