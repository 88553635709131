/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useEffect, useRef } from "react";
import styles from "./SliderLotsCaptions.module.css";
import { Tooltip } from "src/app/components/tooltip";
import { useIsMobile } from "src/app/hooks/useIsMobile";

export const SliderLotsCaptions = ({
  data,
  activeSlideIndex,
  onChangeSlideIndex,
  hasBackground,
}) => {
  const ulRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    const activeLi = ulRef.current?.querySelector(
      `li:nth-child(${activeSlideIndex + 1})`,
    );
    if (!ulRef.current || !activeLi) return;
    ulRef.current.scrollTo({
      top: activeSlideIndex * activeLi?.offsetHeight,
      behavior: "smooth",
    });
  }, [activeSlideIndex]);

  return (
    <ul
      ref={ulRef}
      className={`${
        !data?.experience?.images?.length
          ? "textDark winesList"
          : "textWhite winesList"
      } ${styles.hideScrollbar} ${styles.ul} ${isMobile ? styles.mobile : ""}`}
    >
      {data?.lot?.components.map((element, index) => (
        <li
          key={`slide-collections-${index}-${element.NameProductWithoutVintage}`}
          className={activeSlideIndex === index ? styles.highlighted : ""}
          onClick={() => onChangeSlideIndex(index)}
        >
          <h4
            className={`mb-2 ${
              activeSlideIndex === index ? styles.highlighted_title : ""
            } ${
              hasBackground
                ? activeSlideIndex === index
                  ? styles.highlighted_title_white_bold
                  : styles.highlighted_title_white
                : ""
            } ${styles.title}`}
          >
            {element.nameProductWithoutVintage}
          </h4>
          <div
            className="mb-3"
            style={{
              color: hasBackground ? "white" : "",
              fontSize: "12px",
              lineHeight: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {`${+element.vintage || "NV"} | ${element.quantity} X ${
              element.size
            }L `}
            {!!element?.signed && element?.person_signing !== "" && (
              <div className="ml-2">
                <Tooltip
                  placement="bottom"
                  overlay={<div>Signed by: {element.person_signing}</div>}
                >
                  <svg
                    fill={hasBackground ? "#FFFFFF" : ""}
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 640 512"
                  >
                    <path d="M192 128c0-17.7 14.3-32 32-32s32 14.3 32 32v7.8c0 27.7-2.4 55.3-7.1 82.5l-84.4 25.3c-40.6 12.2-68.4 49.6-68.4 92v71.9c0 40 32.5 72.5 72.5 72.5c26 0 50-13.9 62.9-36.5l13.9-24.3c26.8-47 46.5-97.7 58.4-150.5l94.4-28.3-12.5 37.5c-3.3 9.8-1.6 20.5 4.4 28.8s15.7 13.3 26 13.3H544c17.7 0 32-14.3 32-32s-14.3-32-32-32H460.4l18-53.9c3.8-11.3 .9-23.8-7.4-32.4s-20.7-11.8-32.2-8.4L316.4 198.1c2.4-20.7 3.6-41.4 3.6-62.3V128c0-53-43-96-96-96s-96 43-96 96v32c0 17.7 14.3 32 32 32s32-14.3 32-32V128zm-9.2 177l49-14.7c-10.4 33.8-24.5 66.4-42.1 97.2l-13.9 24.3c-1.5 2.6-4.3 4.3-7.4 4.3c-4.7 0-8.5-3.8-8.5-8.5V335.6c0-14.1 9.3-26.6 22.8-30.7zM24 368c-13.3 0-24 10.7-24 24s10.7 24 24 24H64.3c-.2-2.8-.3-5.6-.3-8.5V368H24zm592 48c13.3 0 24-10.7 24-24s-10.7-24-24-24H305.9c-6.7 16.3-14.2 32.3-22.3 48H616z" />
                  </svg>
                </Tooltip>
              </div>
            )}
          </div>
        </li>
      ))}
    </ul>
  );
};
