import styles from "./profile-sidebar.module.css";
import { NavLink } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useIsMobile } from "src/app/hooks/useIsMobile";

export function UserProfileSidebar() {
  const history = useHistory();
  const location = useLocation();
  const isMobile = useIsMobile();

  const switchLocation = (event) => {
    history.push(event.target.value);
  };

  if (isMobile) {
    return (
      <div className={styles.dropdown}>
        <select
          className={styles.dropdown}
          value={location.pathname}
          onChange={switchLocation}
        >
          <option value={`/user-profile/account-information`}>
            Account Info
          </option>
          <option value={`/user-profile/my-address`}>Address</option>
          <option value={`/user-profile/change-password`}>
            Change Password
          </option>
        </select>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <ul>
        <li>
          <NavLink
            to={"/user-profile/account-information"}
            exact
            activeClassName="active"
          >
            Account Info
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"/user-profile/my-address"}
            exact
            activeClassName="active"
          >
            Address
          </NavLink>
        </li>
        <li>
          <NavLink
            to={"/user-profile/change-password"}
            exact
            activeClassName="active"
          >
            Change Password
          </NavLink>
        </li>
      </ul>
    </div>
  );
}
