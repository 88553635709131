import LeftSideToRefactor from "./left-side-to-refactor";
import { Form } from "../../auction-card/lg/atoms.form";
import { Root } from "../../auction-card/lg/atoms/root";
import { CardCtxProvider } from "../../auction-card/card-context";

export const AuctionCardLgNewToRefactor = ({
  auction: initialAuction,
  cardId = "",
}) => {
  if (initialAuction.isForStore) {
    return (
      <CardCtxProvider initialAuction={initialAuction}>
        <Root
          cardId={cardId}
          overview={<LeftSideToRefactor />}
          form={<Form.ForStore />}
        />
      </CardCtxProvider>
    );
  }

  if (initialAuction.isOfSingleLot) {
    return (
      <CardCtxProvider initialAuction={initialAuction}>
        <Root
          cardId={cardId}
          overview={<LeftSideToRefactor />}
          form={<Form.ForSingleLot />}
        />
      </CardCtxProvider>
    );
  }

  return (
    <CardCtxProvider initialAuction={initialAuction}>
      <Root
        cardId={cardId}
        overview={<LeftSideToRefactor />}
        form={<Form.ForCollection />}
      />
    </CardCtxProvider>
  );
};
