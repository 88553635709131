import { IoMdRefresh } from "react-icons/io";
import { SpinnerSm } from "src/app/components/spinner-sm";
import { useCardCtx } from "../../../card-context";
import { useIsFetching } from "@tanstack/react-query";
import { QKEY } from "src/app/api/QKEY";
import { Button } from "src/app/components/button";

export default function ButtonAuctionRefresh() {
  const { auction, refetchAuction } = useCardCtx();
  const isFetching = useIsFetching({
    queryKey: [QKEY.AUCTION, auction.id_auction, auction.type_product],
  });

  return (
    <Button
      variant="ghost"
      onClick={() => refetchAuction()}
      label={isFetching ? <SpinnerSm size={19} /> : <IoMdRefresh size={21} />}
      disabled={!!isFetching}
    />
  );
}
