import { noop } from "lodash";
import { Button } from "src/app/components/button";

type Props = {
  onClick: () => void;
  isWished: boolean;
  isLoading: boolean;
};

export const ButtonWish = ({
  onClick = noop,
  isWished = false,
  isLoading = false,
}: Props) => {
  return (
    <Button
      variant="outlined"
      size={50}
      onClick={() => onClick()}
      tooltip={isWished ? "Remove from your wishlist" : "Add to your wishlist"}
      isLoading={isLoading}
      icon={<IconWishlist fill={isWished} />}
    />
  );
};

function IconWishlist({ fill = false }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={fill ? "currentColor" : "transparent"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4899_15877)">
        <path
          d="M16.3044 4.4452C15.9213 4.06195 15.4665 3.75793 14.9659 3.55051C14.4653 3.34309 13.9288 3.23633 13.3869 3.23633C12.845 3.23633 12.3085 3.34309 11.8079 3.55051C11.3073 3.75793 10.8525 4.06195 10.4694 4.4452L9.67441 5.2402L8.87941 4.4452C8.10564 3.67143 7.05618 3.23673 5.96191 3.23673C4.86763 3.23673 3.81817 3.67143 3.04441 4.4452C2.27064 5.21897 1.83594 6.26842 1.83594 7.3627C1.83594 8.45697 2.27064 9.50643 3.04441 10.2802L3.83941 11.0752L9.67441 16.9102L15.5094 11.0752L16.3044 10.2802C16.6877 9.89713 16.9917 9.44231 17.1991 8.94171C17.4065 8.44112 17.5133 7.90456 17.5133 7.3627C17.5133 6.82083 17.4065 6.28428 17.1991 5.78368C16.9917 5.28309 16.6877 4.82826 16.3044 4.4452V4.4452Z"
          stroke="#800C32"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4899_15877">
          <rect
            width="18"
            height="18"
            fill="none"
            transform="translate(0.671875 0.987305)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
