import styles from "./left-side-to-refactor.module.css";
import { useRef, useState } from "react";
import LeftSideSubtitleToRefactor from "./left-side-subtitle-to-refactor";
import LeftSideTitleToRefactor from "./left-side-title-to-refactor";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import bottles from "src/media/bottles-bottom.svg";
import custom_label from "src/media/custom-label.svg";
import visit_label from "src/media/common/ccbth-icon-3.svg";
import dinner_label from "src/media/common/ccbth-icon-4.svg";
import { useInView } from "react-intersection-observer";
import { cn } from "src/app/utils/cn";
import { SliderLotsCaptions } from "src/app/modules/Includes/CustomerSide/components/LotsCard/SliderLotsCaptions";
import SliderLots from "src/app/modules/Includes/CustomerSide/components/LotsCard/SliderLots";
import { Tooltip } from "../../tooltip";
import { BarrelInput } from "../../barrel-input";
import { useIsAuctionFinished } from "src/app/hooks/useIsAuctionFinished";
import { ID_IO_ROOT } from "src/app/const";
import { useCardCtx } from "../card-context";
import { Auction, isAuctionOfLot } from "src/app/models/auctions";
import { SwiperRef } from "swiper/react";

export default function LeftSideToRefactor() {
  const cardCtx = useCardCtx();
  const swiperRefParent = useRef<SwiperRef>(null);
  const isMobile = useIsMobile();
  const { ref, isCardVisible, backgroundImage } = useCardBackground(
    cardCtx.auction,
  );
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const isFinished = useIsAuctionFinished(cardCtx.auction);

  function handleChangeSlideIndex(slideIndex: number) {
    setActiveSlideIndex(slideIndex);
    // swiperRefParent.current.swiper.slideTo(slideIndex);
  }

  return (
    <div
      ref={ref}
      style={{ backgroundImage, maxHeight: !isMobile ? 430 : "100%" }}
      className={`${
        cardCtx.auction.experience === null &&
        cardCtx.auction.type_product !== "barrel"
          ? cn(styles.left, "textDark", isMobile && styles.mobile)
          : cn(styles.left, "textWhite", isMobile && styles.mobile)
      } `}
    >
      {!!cardCtx.auction.experience?.images.length && (
        <div className={styles.overlay} />
      )}

      <div
        className="row h-100"
        style={{ marginLeft: 0, marginRight: 0, alignItems: "center" }}
      >
        <div
          className={cn(
            "col-lg-7 d-flex flex-column pl-0 h-100",
            styles.superleft,
          )}
        >
          <LeftSideTitleToRefactor
            auction={cardCtx.auction}
          >
            <LeftSideSubtitleToRefactor
              addClass="noFlex"
              auction={cardCtx.auction}
              experience={cardCtx.auction.experience}
              // title={sellerDescription}
              // moreInformation={
              //   <ContCardSlidePopup
              //     auction={cardCtx.auction}
              //   />
              // }
            />
          </LeftSideTitleToRefactor>

          {cardCtx.auction.type_product !== "barrel" && (
            <SliderLotsCaptions
              data={cardCtx.auction}
              hasBackground={!!cardCtx.auction.experience?.images.length}
              activeSlideIndex={activeSlideIndex}
              onChangeSlideIndex={handleChangeSlideIndex}
              // parentRef={swiperRefParent}
            />
          )}

          {cardCtx.auction.type_product === "barrel" && (
            <div className={`${styles.bottom} `} style={{ marginTop: "auto" }}>
              <div className="row h-100">
                <div className="col-6 mb-5">
                  <div>
                    <img src={bottles} alt="bottled in" />
                  </div>
                  <p>
                    <small>
                      Bottled in&nbsp;
                      {cardCtx.auction.barrel.availableBottleTiers
                        .map((tier) => tier.value)
                        .join(", ")}
                      &nbsp;L
                    </small>
                  </p>
                </div>
                {!!cardCtx.auction.enable_custom_label_bottle && (
                  <div className="col-6 mb-5">
                    <div>
                      <img src={custom_label} alt="custom label" />
                    </div>
                    <p>
                      <small>Custom label available</small>
                    </p>
                  </div>
                )}
                {!!cardCtx.auction.barrel.notes_visit_cellar_of_producer && (
                  <div className="col-6">
                    <div>
                      <Tooltip
                        content={
                          cardCtx.auction.barrel.notes_visit_cellar_of_producer
                        }
                      >
                        <img
                          src={visit_label}
                          alt="custom label"
                          style={{ position: "relative", left: "-6px" }}
                        />
                      </Tooltip>
                    </div>
                    <p>
                      <small>Visit to producer</small>
                    </p>
                  </div>
                )}
                {!!cardCtx.auction.barrel.dinner_with_producer && (
                  <div className="col-6">
                    <div>
                      <Tooltip
                        content={
                          cardCtx.auction.barrel.notes_dinner_with_producer
                        }
                      >
                        <img src={dinner_label} alt="custom label" />
                      </Tooltip>
                    </div>
                    <p>
                      <small>Dinner with producer</small>
                    </p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="col-lg-5 my-5 my-lg-0 h-100 d-flex align-items-center justify-content-center">
          {!!isCardVisible && !!isAuctionOfLot(cardCtx.auction) && (
            <SliderLots
              hasBackground={!!cardCtx.auction.experience?.images.length}
              gallery={cardCtx.auction.lot?.components.map(
                (wine) => wine.media.cover,
              )}
              parentRef={swiperRefParent}
              activeSlideIndex={activeSlideIndex}
              totalSlidesCount={cardCtx.auction.lot?.components.length}
              onChangeSlideIndex={handleChangeSlideIndex}
            />
          )}
          {cardCtx.auction.type_product === "barrel" && (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: `${isMobile ? "auto" : "430px"}` }}
            >
              <BarrelInput
                max={cardCtx.auction.options.quantities.max}
                min={cardCtx.auction.options.quantities.min}
                quantity={cardCtx.quantity}
                onChange={cardCtx.setQuantity}
                step={cardCtx.auction.options.quantities.step}
                mul={cardCtx.auction.options.quantities.mul}
                color={cardCtx.auction.barrel.color as any} // TODO: fix, sync 'color' prop for barrel dto
                disabled={!cardCtx.auction.canBid || isFinished}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function useCardBackground(auction: Auction) {
  const { ref, inView } = useInView({
    rootMargin: "600px 0px",
    triggerOnce: true,
    root: document.getElementById(ID_IO_ROOT),
  });

  const src = auction.experience?.images[0]?.image;

  return {
    ref,
    backgroundImage: inView && src ? `url(${src})` : undefined,
    isCardVisible: inView,
  };
}
