import { Redirect, Route, Switch } from "react-router-dom";
import { usePageTitle } from "../../hooks/usePageTitle.ts";
import { MyPayments } from "./my-payments";
import { MyProfile } from "./my-profile";
import { MyPassword } from "./my-password";
import { MyAddress } from "./my-address";
import { Navbar } from "./navbar";
import { PAGE_TITLE } from "src/app/const";
import MySubscriptionPage from "./my-subscriptions";

export default function UserProfilePage() {
  usePageTitle(PAGE_TITLE.MY_PROFILE());

  return (
    <div className="cru-profile-main">
      <section className="collections-container" style={{ paddingTop: "20px" }}>
        <div className="collections-container-inner">
          <Navbar />

          <Switch>
            {/* Profile tab */}
            <Route
              path="/user-profile/account-information"
              component={MyProfile}
            />
            <Route
              path="/user-profile/change-password"
              component={MyPassword}
            />
            <Route path="/user-profile/my-address" component={MyAddress} />
            {/* Subscriptions tab */}
            <Route
              path="/user-profile/my-subscriptions"
              component={MySubscriptionPage}
            />
            {/* Payments tab */}
            <Route
              path="/user-profile/method-payments"
              component={MyPayments}
            />
            {/* Falback redirect */}
            <Route>
              <Redirect to="/user-profile/account-information" />
            </Route>
          </Switch>
        </div>
      </section>
    </div>
  );
}
