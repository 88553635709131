import styles from "./bid-status.module.css";
import { cn } from "src/app/utils/cn";

const statusDict = {
  pending: "Pending Offer",
  loser: "Loser",
  winner: "Winner",
  deleted: "Offer Deleted",
};

export const BidStatus = ({
  status,
}: {
  status: "pending" | "loser" | "winner" | "deleted";
}) => {
  return (
    <button className={cn(styles.root, styles[status])} disabled>
      {statusDict[status]}
    </button>
  );
};
