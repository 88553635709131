import { env } from "src/env";
import { PAGE_NAME, TierName } from "../const";
import { ValueOf } from "../utils/types";
import { Auction } from "../models/auctions";
import { Builder } from "../models/Builder";
import { slugify } from "../utils/slugify";

export const path = {
  adminLegacy: () => `${env.REACT_APP_ADMIN_LEGACY_URL}/auth/login`,
  dashboard: () => "/dashboard",
  logout: () => "/auth/logout",
  login: () => "/auth/login",
  forgotPassword: () => "/auth/forgot-password",
  contact: () => "/contact",
  termsAndConditions: () => `${env.REACT_APP_WEBSITE}/terms-and-conditions`,
  cookiePolicy: () => `${env.REACT_APP_WEBSITE}/cookie`,
  404: () => "/error/error404",
  signup: {
    definition: () => "/signup/:tier?",
    planSelection: () => "/signup",
    steps: {
      definition: () => "/signup/:tier",
      value: (tierName: TierName) => `/signup/${tierName}`,
    },
  },
  store: {
    definition: () => "/store/:pageName?",
    shop: () => `/store/${PAGE_NAME.STORE.SHOP}`,
    privateSale: () => `/store/${PAGE_NAME.STORE.PRIVATE_SALE}`,
    enPrimeur: () => `/store/${PAGE_NAME.STORE.EN_PRIMEUR}`,
  },
  sales: {
    definition: () => `/sales/:type_product/:id_auction_and_auction_name`,
    value: (auction: Auction) =>
      // ! Hard coded in WhatsNewSlider
      `/sales/${auction.type_product}/${auction.id}-${slugify(
        auction.description,
      )}`,
  },
  profile: {
    value: () => "/user-profile",
    subscription: () => `${path.profile.value()}/my-subscriptions`,
    payments: () => `${path.profile.value()}/method-payments`,
  },
  provenance: {
    definition: () => `/provenance/:skuOrSerial`,
    value: (skuOrSerial: string) => `/provenance/${skuOrSerial}`,
  },
  shipments: () => "/shipments",
  outstandingPayments: () => "/outstanding-payments",
  walletNft: (addressKey: string) =>
    `${env.REACT_APP_EXPLORER_URL}/address/${addressKey}`,
  forbidden: () => "/error/error-forbidden",
  customerPortfolio: () => "/customer-portfolio",
  myActivities: {
    definition: () => "/my-activities/:pageName?",
    value: () => "/my-activities",
    archive: () => `/my-activities/${PAGE_NAME.MY_ACTIVITIES.MY_ARCHIVE}`,
    offers: {
      definition: () => `/my-activities/${PAGE_NAME.MY_ACTIVITIES.MY_OFFERS}`,
      value: () =>
        `/my-activities/${PAGE_NAME.MY_ACTIVITIES.MY_OFFERS}?Offer%20Status=%5B"pending"%5D`,
    },
    wishlist: () => `/my-activities/${PAGE_NAME.MY_ACTIVITIES.MY_WISHLIST}`,
  },
  myAssets: {
    value: () => "/my-assets",
    lots: () => `/my-assets/lots`,
    barrels: () => `/my-assets/barrels`,
    experiences: () => `/my-assets/experiences`,
    bottles: () => `/my-assets/bottles`,
  },
  weekly: {
    definition: () => "/weekly/auctions/:pageName?",
    value: (
      pageName?: ValueOf<typeof PAGE_NAME.WEEKLY>,
      id?: string | number,
    ) => `/weekly/auctions/${pageName ?? ""}${id ? `?id=${id}` : ""}`,
  },
  website: {
    singleProducer: (builder: Builder) =>
      `${env.REACT_APP_WEBSITE}/producers/${builder.id}-${slugify(
        builder.name,
      )}`,
  },
  nftMetadata: {
    definition: () => "/media/nft/metadata/wines/:tokenId",
    value: (tokenId: string) => `/media/nft/metadata/wines/${tokenId}`,
  },
};
