import styles from "./styles.module.css";
import { useState } from "react";
import { AddressForm } from "src/app/components/address-form/address-form";
import { Drawer } from "src/app/components/drawer";
import { RadioGroupAddress } from "src/app/components/shipment-form/radiogroup-address";
import { ShipmentV1 } from "src/app/models/ShipmentV1";
import { AddressV1 } from "src/app/models/Address";
import { Button } from "src/app/components/button";
import { useUpdateShipmentMutation } from "src/app/api/shipments";
import { useQueryClient } from "@tanstack/react-query";
import { DisplaySelectedAddress } from "src/app/components/shipment-form/display-selected-address";

export function EditShipmentForm({
  shipping,
  closeDrawer,
}: {
  shipping: ShipmentV1;
  closeDrawer: () => void;
}) {
  const updateShipmentMutation = useUpdateShipmentMutation(
    shipping.id_shipping,
  );

  const queryClient = useQueryClient();

  const [showAddressFormModal, setShowAddressFormModal] = useState(false);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<AddressV1 | undefined>(
    undefined,
  );
  const [selectedAddress, setSelectedAddress] = useState<AddressV1 | undefined>(
    shipping.shipping_address,
  );

  return (
    <>
      {!!showAddressFormModal && (
        <Drawer
          title={
            addressToEdit
              ? "Edit Address for shipping"
              : "Add Address for shipping"
          }
          position="right"
          width={570}
          onClosed={() => {
            setShowAddressFormModal(false);
            setAddressToEdit(undefined);
          }}
          render={({ close }) => (
            <AddressForm
              onSuccess={() => {
                close();
                setAddressToEdit(undefined);
              }}
              onCancel={() => {
                close();
                setAddressToEdit(undefined);
              }}
              addressType="shipping"
              initialAddress={addressToEdit}
            />
          )}
        />
      )}

      <div className={styles.root}>
        <div className={styles.section}>
          <h3>Lots</h3>
          <div>
            <div className={styles.container}>
              <div style={{ fontWeight: "600" }}>Name</div>
              <div style={{ fontWeight: "600" }}>Quantity Available</div>
            </div>
            {shipping.lots?.map((lot) => (
              <div key={lot.id} className={styles.container}>
                <div className={styles.description}>{lot.description}</div>
                <input
                  disabled
                  className={styles.quantity}
                  value={lot.quantity}
                />
              </div>
            ))}
          </div>
        </div>

        {!!isEditingAddress && (
          <RadioGroupAddress
            onChange={(address) => {
              setSelectedAddress(address);
            }}
            selectedAddress={selectedAddress}
            initialSelectedAddress={shipping.shipping_address}
            onCreate={() => setShowAddressFormModal(true)}
            onEdit={(address) => {
              setAddressToEdit(address);
              setShowAddressFormModal(true);
            }}
            onConfirm={() => setIsEditingAddress(false)}
          />
        )}

        {!!selectedAddress && !isEditingAddress && (
          <div className={styles.section}>
            <h3>Selected Address</h3>
            <DisplaySelectedAddress address={selectedAddress} />

            <div style={{ display: "flex", gap: "20px" }}>
              <Button
                isLoading={!!updateShipmentMutation.isLoading}
                label="Ship to this address"
                disabled={!!updateShipmentMutation.isLoading}
                onClick={() => {
                  updateShipmentMutation
                    .mutateAsync({
                      id_address: selectedAddress.id_address,
                      pickup_address: shipping.pickup_address,
                    })
                    .then(() => {
                      queryClient.invalidateQueries();
                      closeDrawer();
                    });
                }}
              />
              <Button
                label="Edit Address"
                onClick={() => setIsEditingAddress(true)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}
