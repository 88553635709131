import { useState } from "react";
import {
  AlertPopup,
  AlertPopupMeta,
} from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import SkeletonCollectionCard from "src/app/modules/Includes/CustomerSide/components/skeleton/SkeletonCollectionCard";
import { NoBidsNew } from "src/app/modules/Includes/CustomerSide/components/common/NoBidsNew";
import { Navigation } from "../navigation";

import { capitalize, range } from "lodash";
import { Auction } from "src/app/models/auctions";
import { useAuctionsQuery } from "src/app/api/auctions";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { useSelectMultipleFromData } from "src/app/components/form-elements/select-multiple/useSelectMultipleFromData";
import { SelectMultipleFromData } from "src/app/components/form-elements/select-multiple/from-data";
import { StickyControls } from "src/app/components/sticky-controls";
import { inferDataType } from "src/app/utils/types";
import { PAGE_TITLE } from "src/app/const";
import { AuctionCardLgNewToRefactor } from "src/app/components/auction-card/lg";

export const PageOffers = () => {
  usePageTitle(PAGE_TITLE.MY_ACTIVITIES());
  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );
  const selectMultiple = useSelectMultipleFromData({
    inferDataType: inferDataType<Auction>(),
    config: [
      {
        groupName: "Offer Status",
        getValue: (auction) => auction.bets.map((bid) => bid.bid_status.label),
        getLabel: (auction) =>
          auction.bets.map((bid) => capitalize(bid.bid_status.label)),
      },
    ],
  });

  const auctionsQuery = useAuctionsQuery({ pageName: "offers" });

  const filteredAuctions = (auctionsQuery.data || [])
    .filter(selectMultiple.filterFn)
    .filter(
      (auction, index, self) =>
        index === self.findIndex((t) => t.id_auction === auction.id_auction),
    );

  return (
    <div>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <StickyControls
        slot1={() => <Navigation />}
        slot3={(scrollToTop) => (
          <SelectMultipleFromData
            placeholder="Filters"
            data={filteredAuctions}
            config={selectMultiple.config}
            onChange={(values) => {
              selectMultiple.setValues(values);
              scrollToTop();
            }}
            values={selectMultiple.values}
          />
        )}
      />

      <div
        className="lots-collection-cards-container"
        style={{ marginBottom: "24px" }}
      >
        {(!!auctionsQuery.isLoading ||
          (!!auctionsQuery.isFetching && !filteredAuctions.length)) &&
          range(0, 4).map((i) => (
            <SkeletonCollectionCard key={`skel-card-arch-${i}`} />
          ))}

        {!auctionsQuery.isLoading &&
          !auctionsQuery.isFetching &&
          !filteredAuctions.length && <NoBidsNew title="Nothing found" />}

        {filteredAuctions.map((auction) => (
          <AuctionCardLgNewToRefactor
            key={`auction-${auction.id_auction}-${auction.myLastBid?.bid_id}`}
            auction={auction}
            cardId={`auction-${auction.id_auction}`}
          />
        ))}
      </div>
    </div>
  );
};
