import { range } from "lodash";
import { UserV1 } from "./models/UserV1";
import { ValueOf } from "./utils/types";

export const ID_AUCTION_TYPE = {
  SINGLE_LOTS: 1,
  COLLECTION: 2,
  PRIVATE_SALE: 3,
  PERMANENT: 5,
  EN_PRIMEUR: 6,
  METAVERSE: 9,
} as const;

export type IdAuctionType = ValueOf<typeof ID_AUCTION_TYPE>;

export const TYPE_PRODUCT = {
  LOT: "lot",
  BARREL: "barrel",
} as const;

export type TypeProduct = ValueOf<typeof TYPE_PRODUCT>;

export const MERCH_TYPE = {
  LOTS: "LOTS",
  BARRELS: "BARRELS",
} as const;

export const ID_ROLE = {
  ADMIN: 1,
  CUSTOMER: 2,
  SUPPLIER: 3,
  MANAGER: 4,
  SALES_MANAGER: 5,
  BD_MANAGER: 6,
  LOGISTICS: 7,
  PRODUCT_MANAGER: 8,
  GUEST: 10,
  ACCOUNTING_PROFILE: 11,
  PRO_ADMIN: 12,
  SALES_TEAM: 13,
  SUPER_ADMIN: 99,
} as const;

export type IdRole = ValueOf<typeof ID_ROLE>;

export const ID_TIER = {
  EXPLORER: 12,
  CONNOISSEUR: 3,
  COLLECTOR: 2,
  AMBASSADOR: 1,
  FOUNDER: 13,
  PROFESSIONAL: 14,
  STAFF: 15,
} as const;

export type IdTier = ValueOf<typeof ID_TIER>;

export const TIER_NAME = {
  EXPLORER: "explorer",
  CONNOISSEUR: "connoisseur",
  COLLECTOR: "collector",
  AMBASSADOR: "ambassador",
} as const;

export type TierName = ValueOf<typeof TIER_NAME>;

export const STORAGE_KEY = {
  ACCESS_TOKEN: "accessToken",
  SHOW_MY_EARNINGS: "show-my-earnings",
  ADMIN_IMPERSONATE: "admin_impersonate",
  USER_LOGGED: "user_logged",
} as const;

export const PAGE_NAME = {
  WEEKLY: {
    ALL: "all",
    CHARITY: "charity",
    COLLECTIONS: "collections",
    SINGLE_LOTS: "single-lots",
    BARRELS: "barrels",
  } as const,
  STORE: {
    SHOP: "shop",
    PRIVATE_SALE: "private-sale",
    EN_PRIMEUR: "en-primeur",
  } as const,
  MY_ACTIVITIES: {
    MY_OFFERS: "offers",
    MY_WISHLIST: "wishlist",
    MY_ARCHIVE: "archive",
  } as const,
} as const;

export type PageName =
  | ValueOf<typeof PAGE_NAME.WEEKLY>
  | ValueOf<typeof PAGE_NAME.STORE>
  | ValueOf<typeof PAGE_NAME.MY_ACTIVITIES>;

export const PAGE_TITLE = {
  SHOP: () => "Shop" as const,
  EN_PRIMEUR: () => "Bordeaux En Primeur" as const,
  PRIVATE_SALE: () => "Private Sale" as const,
  WEEKLY_AUCTIONS: () => "Weekly Offers" as const,
  OUTSTANDING_PAYMENTS: () => "Outstanding Payments" as const,
  DASHBOARD: (user: UserV1) =>
    `<span style="display:flex;flex-direction:column;gap:2px;" id="greeting">
        <span style="font-size: 14px;font-family:Inter;font-weight:400;display:block;">
          Hi ${user.first_name}
        </span>
        Welcome Back
      </span>` as const,
  CONTACT: () => "Contact" as const,
  MY_ACTIVITIES: () => "Your Activities" as const,
  MY_ASSETS: () => "Your Cellar" as const,
  MY_PROFILE: () => "Profile" as const,
  MY_SHIPMENTS: () => "Your Shipments" as const,
  TERMS_AND_CONDITIONS: () => "Terms & Conditions" as const,
  PENDING_GIFTS: () => "Pending Gifts" as const,
  NONE: () => "" as const,
};

export type PageTitle = ReturnType<
  (typeof PAGE_TITLE)[keyof typeof PAGE_TITLE]
>;

export const SHIPPING_STATUS = {
  SHIPMENT_REQUEST: 0,
  PENDING_PAYMENT: 1,
  UNDER_PROCESSING: 2,
  SHIPPED: 3,
  DELIVERED: 4,
} as const;

export const BID_STATUS = {
  PENDING: "pending",
  LOSER: "loser",
  WINNER: "winner",
  DELETED: "deleted",
} as const;

export const ID_LOT_STATUS = {
  NOT_ASSOCIATED: 1,
  DELIVERED: 2,
  SHIPMENT_REQUEST: 3,
  GIFTED: 4,
  OUTSTANDING_PAYMENT: 5,
  RESELL: 6,
  WITH_PRODUCER: 7,
  IN_OUR_WAREHOUSE: 8,
} as const;

export const ID_PRODUCT_CATEGORY = {
  WINE: 7,
  SPIRIT: 8,
} as const;

export const PLAN_RECURRENCE = {
  ANNUAL: "annual",
  MONTHLY: "monthly",
} as const;

export type IdProductCategory = ValueOf<typeof ID_PRODUCT_CATEGORY>;

export const ID_IO_ROOT = "intersection-observer-root";

export const ID_TOPBAR_CONTAINER = "topbar-container";

export const ENPRIMEUR_QUANTITY_OPTIONS: readonly number[] = [1, 3].concat(
  range(6, 260, 6),
);

export const PAYMENT_STATUS = {
  UNPAID: -1,
  PENDING: 0,
  PAID: 1,
  MARKED_AS_PAID: 2,
} as const;

export type PaymentStatus = ValueOf<typeof PAYMENT_STATUS>;

export const ID_PAYMENT_METHOD = {
  CARD: 3,
  BANK: 4,
} as const;

export type IdPaymentMethod = ValueOf<typeof ID_PAYMENT_METHOD>;
