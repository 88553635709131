import "./styles-to-refactor.css";
import styles from "./left-side-title-to-refactor.module.css";
import { useIsMobile } from "src/app/hooks/useIsMobile";
import { Tooltip } from "../../tooltip";
import dateOfArrival from "src/images/common/date-of-arrival.svg";
import time from "src/images/common/hourglass-solid.svg";
import { path } from "src/app/routes/path";
import bottlesImage from "src/media/barrel/wine-bottle.png";
import { cn } from "src/app/utils/cn";
import { A } from "../../a";
import {
  Auction,
  isAuctionOfBarrel,
  isAuctionOfLot,
} from "src/app/models/auctions";
import { ReactNode } from "react";
import { Product } from "src/app/models/Product";

const strLengthMax = 150;

type Props = {
  auction: Auction;
  children: ReactNode;
  subTitle?: string;
  subNote?: string;
  additionalClass?: string;
  additionalLabelClass?: string;
};

export default function LeftSideTitleToRefactor({
  auction,
  subTitle,
  subNote = undefined,
  additionalClass = "",
  additionalLabelClass = "",
  children,
}: Props) {
  const isMobile = useIsMobile();
  const fixedLengthSubtitle =
    subTitle && subTitle.length > strLengthMax
      ? subTitle.substring(0, strLengthMax - 3) + "..."
      : subTitle;

  return (
    <>
      <div className="experiencesTitle">
        <div className={`d-flex labelWrap`}>
          {auction.tags.map((element, index) => {
            const labelClass = `${styles.label} ${additionalLabelClass} mr-2`;
            return (
              <div
                key={`bad-key-${index}`}
                style={{ textTransform: "capitalize" }}
                className={cn(styles.flexWrap, styles.grey)}
              >
                <div
                  style={{ textTransform: "capitalize", fontWeight: 500 }}
                  className={labelClass}
                >
                  {element.toLowerCase()}
                </div>
              </div>
            );
          })}
        </div>

        <h3
          style={{ fontSize: "26px", fontWeight: 500 }}
          className={cn(
            "title",
            additionalClass,
            auction.experience?.images.length ? styles.light : styles.dark,
          )}
        >
          <A className={styles.titleLink} to={path.sales.value(auction)}>
            {auction.description}
          </A>
        </h3>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: isMobile ? "wrap" : "nowrap",
            gap: 0,
          }}
        >
          <span
            style={{ marginRight: "6px" }}
            className={cn(
              additionalClass,
              auction.experience?.images.length ? styles.light : styles.grey,
            )}
          >
            {!auction.experience &&
              isAuctionOfLot(auction) &&
              !!auction.lot?.components.length &&
              getTypeFromTags(auction.tags, "spirits") && <div />}
            <div
              className="mb-3"
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                gap: "6px",
              }}
            >
              {auction.experience?.attributes.map(
                (attr) =>
                  attr.icon && (
                    <Tooltip
                      content={attr.attribute}
                      key={`${auction.id}-${auction.experience?.id}-${attr.id}`}
                    >
                      <img
                        key={attr.icon}
                        className={styles.attributeIcon}
                        style={{ margin: 0 }}
                        src={attr.icon}
                        alt={attr.attribute}
                      />
                    </Tooltip>
                  ),
              )}
            </div>
            <div style={{ display: "flex" }}>
              {isAuctionOfLot(auction) &&
                !!auction.lot?.components.length &&
                getTypeFromTags(auction.tags, "wine") && (
                  <div
                    style={{
                      fontSize: "14px",
                      marginBottom: "5px",
                      marginRight: "3px",
                    }}
                  >
                    {sumQuantity(auction.lot.components)}{" "}
                    {sumQuantity(auction.lot.components) > 1
                      ? " Bottles"
                      : " Bottle"}
                    {!!auction.experience && " | "}
                  </div>
                )}

              {!!auction.experience && (
                <span className="mb-2" style={{ fontSize: "14px" }}>
                  {location(auction.experience.country.name)}
                </span>
              )}
            </div>
            <div
              className="d-flex viewDetails"
              style={{ alignItems: "center", gap: "8px 0" }}
            >
              {isAuctionOfBarrel(auction) && (
                <>
                  {!!auction.barrel.final_bottling_date && (
                    <div
                      className="mr-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        position="bottom"
                        content="Initial Bottling Date."
                      >
                        <div style={{ lineHeight: "24px" }}>
                          <img
                            width="20"
                            height="19"
                            src={bottlesImage}
                            alt="dateOfArrival"
                            className="mr-1"
                            style={{
                              position: "relative",
                              top: "-1px",
                              marginRight: "6px",
                            }}
                          />

                          {formatDate(
                            auction.barrel.final_bottling_date,
                            "D M, Y",
                          )}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {!!auction.barrel.lots_available_date && (
                    <div
                      className="mr-3"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip
                        position="bottom"
                        content="Estimated date of arrival in our warehouse."
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                            lineHeight: "24px",
                          }}
                        >
                          <img src={dateOfArrival} alt="dateOfArrival" />

                          <span>
                            {new Date(
                              auction.barrel.lots_available_date,
                            ).getTime() > new Date().getTime()
                              ? formatDate(
                                  auction.barrel.lots_available_date,
                                  "D M, Y",
                                )
                              : "Ready to ship"}
                          </span>
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
              {!!auction.experience && (
                <div
                  className="mr-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tooltip
                    position="bottom"
                    content="Expiration date. Please book before."
                  >
                    <img style={{ width: "10px" }} src={time} alt="time" />
                  </Tooltip>
                </div>
              )}

              {!auction.experience &&
                isAuctionOfLot(auction) &&
                !!auction.lot?.available_date && (
                  <div
                    className="mr-4"
                    style={{ fontSize: "13px", lineHeight: "1rem" }}
                  >
                    <Tooltip
                      position="bottom"
                      content="Estimated date of arrival in our warehouse."
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                          lineHeight: "24px",
                        }}
                      >
                        <img src={dateOfArrival} alt="dateOfArrival" />

                        <span>
                          {new Date(auction.lot.available_date).getTime() >
                          new Date().getTime()
                            ? formatDate(auction.lot.available_date, "D M, Y")
                            : "Ready to ship"}
                        </span>
                      </div>
                    </Tooltip>
                  </div>
                )}
              {!!auction.experience?.expiration_date && (
                <div
                  className="mr-4"
                  style={{ fontSize: "13px", lineHeight: "1rem" }}
                >
                  {formatDate(auction.experience.expiration_date, "D M, Y")}
                </div>
              )}
              {!!auction.experience?.start_experience_date && (
                <div
                  className="ml-5 mr-4"
                  style={{ fontSize: "13px", lineHeight: "1rem" }}
                >
                  <span>
                    {formatDate(
                      auction.experience.start_experience_date,
                      "D M, Y",
                    )}
                  </span>
                  {!!auction.experience.end_experience_date && (
                    <>
                      <span>&nbsp;-&nbsp;</span>
                      <span>
                        {formatDate(
                          auction.experience.end_experience_date,
                          "D M, Y",
                        )}
                      </span>
                    </>
                  )}
                </div>
              )}
              {children}
            </div>
          </span>
        </div>

        {!!fixedLengthSubtitle && (
          <span className="subTitle"> {fixedLengthSubtitle}</span>
        )}

        {!!subNote && <span className="subNote"> {subNote} </span>}
      </div>
    </>
  );
}

function formatDate(dateString: string, format: string) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  if (format === "D M, Y") {
    return `${day} ${month}, ${year}`;
  } else if (format === "M, Y") {
    return `${month}, ${year}`;
  }
}

const location = (loc: string) => {
  const parts = loc.split(", ");
  if (parts.length >= 3) {
    return `${parts[parts.length - 3] + ", " + parts[parts.length - 1]}`;
  } else {
    return loc;
  }
};

const getTypeFromTags = (tags: string[], element: string) => {
  return tags.some((tag) =>
    tag.toLowerCase().startsWith(element.toLowerCase()),
  );
};

function sumQuantity(products: Product[]) {
  return products.reduce((acc, current) => acc + (current.quantity || 0), 0);
}
