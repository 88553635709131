import { z } from "zod";
import { ID_TIER } from "../const";

const couponFreeMonthsPerRoleSchema = z.object({
  id_customer_role: z.nativeEnum(ID_TIER),
  name: z.string(),
  months: z.string().transform(Number),
});

export const couponSchemaV1 = z
  .union([
    z.literal(false),
    z.object({
      id_coupon: z.number(),
      code: z.string(),
      name: z.string(),
      roles: z.string().transform((roles) => {
        try {
          return z
            .array(couponFreeMonthsPerRoleSchema)
            .parse(JSON.parse(roles));
        } catch (e) {
          return [];
        }
      }),
      creation_date: z.string().transform((value) => value.replace(/-/g, "/")),
      expiration_date: z
        .string()
        .nullable()
        .transform((value) => value?.replace(/-/g, "/") || null),
      is_infinity: z.union([z.literal(0), z.literal(1)]),
      active: z.union([z.literal(0), z.literal(1)]),
      deleted: z.union([z.literal(0), z.literal(1)]),
      check_credit_card_on_registration: z.union([z.literal(0), z.literal(1)]),
    }),
  ])
  .transform((apiCoupon) => apiCoupon || null);

export type CouponV1 = z.infer<typeof couponSchemaV1>;
