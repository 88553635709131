import iconSrc from "src/media/no-cellar-icon.png";

export function TableNoDataBox({ title, content }) {
  return (
    <div className="cellar-no-data-box">
      <div className="cellar-no-data-box-inner">
        <figure>
          <img src={iconSrc} alt="no-cellar" className="img-responsive" />
        </figure>
        <h3>
          {title && title !== ""
            ? title
            : "There is currently nothing in the table"}
        </h3>

        {content && content !== "" ? content : <></>}
      </div>
    </div>
  );
}
