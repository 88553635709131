import { useMutation } from "@tanstack/react-query";
import { sendCall } from "src/app/config/Utility";
import { useMyUser } from "../hooks/useMyUser";
import { toFormData } from "../utils/toFormData";

export const useUpdateMyUserMutation = (options = {}) => {
  const user = useMyUser();

  return useMutation({
    ...options,
    mutationFn: ({
      pic,
      first_name,
      last_name,
      company_name,
      birthday,
      vat,
      email,
      password,
      consent_profiling,
      consent_marketing,
    }) => {
      const formData = toFormData({
        pic,
        first_name,
        last_name,
        company_name,
        birthday,
        vat,
        email,
        password,
        consent_profiling,
        consent_marketing,
        id_manager: user.id_user,
        id_user_profile: user.id_user,
        accessToken: user.accessToken,
      });

      return sendCall(
        "users/EditPersonalInformationUser/",
        "POST",
        formData,
        {},
      ).then((result) => {
        if (result.code !== 200) {
          throw result;
        }
      });
    },
  });
};
