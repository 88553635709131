import { z } from "zod";
import { apiAuctionBaseSchema } from "./AuctionBase";
import {
  ENPRIMEUR_QUANTITY_OPTIONS,
  ID_AUCTION_TYPE,
  ID_PRODUCT_CATEGORY,
  IdProductCategory,
  TYPE_PRODUCT,
} from "src/app/const";
import { Lot, apiLotSchema } from "../Lot";
import { Experience, apiExperienceSchema } from "../Experience";
import { Bid } from "./Bid";
import { range } from "lodash";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { ValueOf } from "src/app/utils/types";

export const apiAuctionOfLotSchema = apiAuctionBaseSchema.extend({
  rootCategory: z.array(z.nativeEnum(ID_PRODUCT_CATEGORY)),
  type_product: z.literal(TYPE_PRODUCT.LOT),
  lot: apiLotSchema.nullable(), // The lot is nullable because the auction might be for experience only
  experience: apiExperienceSchema.nullable().optional(),
  options: z.object({
    bids: z.array(z.number()),
    quantities: z
      .object({
        min: z.union([z.string(), z.number()]),
        max: z.union([z.string(), z.number()]),
        step: z.union([z.string(), z.number()]),
        mul: z.never().optional(),
      })
      .transform((qties) => ({
        min: Math.max(1, +qties.min),
        max: Math.max(1, +qties.max),
        step: +qties.step,
        mul: undefined,
      })),
  }),
  max_quantity: z.number(),
  quantity: z.number(),
  max_bid_feature_is_available: z.boolean().nullable().optional(),
  user_max_bid_set: z.number().nullable().optional(),
  is_highest_bid: z.boolean().nullable().optional(),
  whyCantBid: z.string().nullable().optional(),
  summer_sale: z.boolean(),
  canAddToWaitingList: z.boolean().nullable().optional(),
  addedToWaitingList: z.boolean().nullable().optional(),
  type: z.object({
    id: z.nativeEnum(ID_AUCTION_TYPE),
    name: z.string(),
    slug: z.union([
      z.literal("private-sale"),
      z.literal("collections"),
      z.literal("permanent-collections"),
      z.literal("single-lots"),
      z.literal("en-primeur"),
    ]),
  }),
});

export type ApiAuctionOfLot = z.infer<typeof apiAuctionOfLotSchema>;

export class AuctionOfLot {
  bets: Bid[];
  bid_percentage: number | null | undefined;
  canBid: boolean;
  catalogue: string;
  closed: boolean;
  createdDate: string;
  created_data: string;
  crurated_estimated_market_price: number;
  currentPrice: number;
  description: string;
  disabled_for: Array<{ id_customer_role: number }>;
  /**
   * @deprecated prefer crurated_estimated_market_price
   */
  estimatedMarketPrice?: number;
  experience?: Experience | null;
  finish_data: string | null;
  id: number;
  id_auction: number;
  increment_selected: number;
  initial_price: number;
  isArchive: boolean;
  isWishlist: boolean;
  is_charity: boolean;
  is_enabled_tech_fee: boolean;
  is_multi_purchase: boolean;
  is_new: boolean;
  is_no_deposit: boolean;
  is_resell: boolean;
  is_sponsored: boolean;
  lastBidDate: string | null | undefined;
  lot: Lot | null;
  max_quantity: number;
  more_information: string;
  only_for_you: boolean;
  options: ApiAuctionOfLot["options"];
  processed: boolean;
  quantity: number;
  rootCategory: IdProductCategory[];
  short_description: string;
  stato: boolean;
  summer_sale: boolean;
  tags: string[];
  tech_fee: number;
  max_bid_feature_is_available: boolean;
  user_max_bid_set: number;
  is_highest_bid: boolean;
  whyCantBid?: string;
  canAddToWaitingList: boolean;
  addedToWaitingList: boolean;
  type: {
    id: ValueOf<typeof ID_AUCTION_TYPE>;
    name: string;
    slug:
      | "private-sale"
      | "collections"
      | "permanent-collections"
      | "single-lots"
      | "en-primeur";
  };
  type_product: typeof TYPE_PRODUCT.LOT;
  visible_for: Array<{ id_customer_role: number }>;

  constructor(apiAuction: ApiAuctionOfLot) {
    this.bets = apiAuction.bets.length
      ? apiAuction.bets.map((bid) => new Bid(bid))
      : [];
    this.bid_percentage = apiAuction.bid_percentage;
    this.canBid = apiAuction.canBid;
    this.catalogue = apiAuction.catalogue ?? "";
    this.closed = !!apiAuction.closed;
    this.createdDate = apiAuction.createdDate ?? apiAuction.created_data;
    this.created_data = apiAuction.created_data;
    this.crurated_estimated_market_price =
      apiAuction.crurated_estimated_market_price;
    this.currentPrice = apiAuction.currentPrice;
    this.description = apiAuction.description ?? "";
    this.disabled_for = parseJSON(apiAuction.disabled_for);
    this.estimatedMarketPrice = apiAuction.estimatedMarketPrice;
    this.experience = apiAuction.experience
      ? new Experience(apiAuction.experience)
      : null;
    this.finish_data = apiAuction.finish_data;
    this.id = apiAuction.id;
    this.id_auction = apiAuction.id_auction;
    this.increment_selected = apiAuction.increment_selected;
    this.initial_price = apiAuction.initial_price;
    this.isArchive = !!apiAuction.isArchive;
    this.isWishlist = !!apiAuction.isWishlist;
    this.is_charity = apiAuction.is_charity;
    this.is_enabled_tech_fee = apiAuction.is_enabled_tech_fee;
    this.is_multi_purchase = !!apiAuction.is_multi_purchase;
    this.is_new = !!apiAuction.is_new;
    this.is_no_deposit = !!apiAuction.is_no_deposit;
    this.is_resell = !!apiAuction.is_resell;
    this.is_sponsored = apiAuction.is_sponsored;
    this.lastBidDate = apiAuction.lastBidDate;
    this.lot = apiAuction.lot ? new Lot(apiAuction.lot) : null;
    this.max_quantity = apiAuction.max_quantity;
    this.more_information = apiAuction.more_information ?? "";
    this.only_for_you = apiAuction.only_for_you;
    this.options = apiAuction.options;
    this.processed = apiAuction.processed;
    this.quantity = apiAuction.quantity;
    this.rootCategory = apiAuction.rootCategory;
    this.short_description = apiAuction.short_description ?? "";
    this.stato = apiAuction.stato;
    this.summer_sale = apiAuction.summer_sale;
    this.tags = this.only_for_you
      ? ["Only for you", ...apiAuction.tags]
      : apiAuction.tags;
    this.tech_fee = apiAuction.tech_fee;
    this.whyCantBid = apiAuction.whyCantBid ?? "";
    this.canAddToWaitingList = apiAuction.canAddToWaitingList ?? false;
    this.addedToWaitingList = apiAuction.addedToWaitingList ?? false;
    this.user_max_bid_set =
      apiAuction.user_max_bid_set || this.myLastBid?.bid_offer || 0;
    this.max_bid_feature_is_available =
      apiAuction.max_bid_feature_is_available ?? false;
    this.is_highest_bid = apiAuction.is_highest_bid ?? false;
    this.type = apiAuction.type;
    this.type_product = apiAuction.type_product;
    this.visible_for = parseJSON(apiAuction.visible_for);
  }

  get productCategories() {
    return this.rootCategory.map((id) =>
      id === ID_PRODUCT_CATEGORY.WINE ? "Wine" : "Spirit",
    );
  }

  get hasSpirit() {
    return this.rootCategory.includes(ID_PRODUCT_CATEGORY.SPIRIT);
  }

  get isOfSingleLot() {
    return this.type.id === ID_AUCTION_TYPE.SINGLE_LOTS && !!this.lot;
  }

  get isOfLotCollection() {
    return this.type.id === ID_AUCTION_TYPE.COLLECTION && !!this.lot;
  }

  get hasExperience() {
    return !!this.experience;
  }

  get isForStore() {
    return (
      this.type.id === ID_AUCTION_TYPE.PERMANENT ||
      this.type.id === ID_AUCTION_TYPE.PRIVATE_SALE ||
      this.type.id === ID_AUCTION_TYPE.EN_PRIMEUR
    );
  }

  get initialQuantity() {
    // Specific handling for En Primeur. Finds the closest option in the array ENPRIMEUR_QUANTITY_OPTIONS
    if (this.type.id === ID_AUCTION_TYPE.EN_PRIMEUR) {
      const options = ENPRIMEUR_QUANTITY_OPTIONS;
      let result = this.options.quantities.min || 1;

      if (options.indexOf(result) === -1) {
        let closestIndex = 0;
        for (let i = 1; i < options.length; i++) {
          if (
            Math.abs(options[i] - result) <
            Math.abs(options[closestIndex] - result)
          ) {
            closestIndex = i;
          }
        }
        result = options[closestIndex];
      }
      return this.myLastPendingBid?.bid_quantity || result;
    }

    // Handling for every other auction type
    if (this.myLastPendingBid) {
      return this.myLastPendingBid.bid_quantity;
    }
    return this.options.quantities.min || 1;
  }

  get quantityOptions() {
    if (this.type.id === ID_AUCTION_TYPE.EN_PRIMEUR) {
      return ENPRIMEUR_QUANTITY_OPTIONS.filter(
        (val) =>
          val >= this.options.quantities.min &&
          val <= this.options.quantities.max,
      ).map((qty) => ({ value: qty, label: `${qty}` }));
    }
    return range(
      this.options.quantities.min,
      this.options.quantities.max + 1,
      this.options.quantities.step,
    ).map((qty) => ({
      value: qty,
      label: `${qty}`,
    }));
  }

  get priceOptions() {
    return this.options.bids.map((price) => ({
      value: price,
      label: formatCurrency(price),
    }));
  }

  get initialPrice() {
    if (this.isOfSingleLot) {
      if (!this.canBid && !!this.myLastBid) {
        // In the case I made a bid and I can't bid
        // I either have the highest offer or the auction is finished.
        // anyways I should see the highest offer, wich is `this.currentPrice`
        return this.currentPrice;
      }
      // the first option is dynamically calculated by the backend and is always correct
      // any other logic is to be considered incorrect
      return this.options.bids[0];
    }
    if (this.myLastPendingBid) {
      return this.myLastPendingBid.bid_offer;
    } else {
      return this.initial_price;
    }
  }

  get myPendingBids() {
    return this.bets.filter((bid) => bid.isPending);
  }

  get myLastPendingBid() {
    if (!this.myPendingBids.length) return null;
    return this.myPendingBids.sort((a, b) =>
      a.bid_date > b.bid_date ? -1 : a.bid_date === b.bid_date ? 0 : 1,
    )[0];
  }

  get myLastBid() {
    if (!this.bets.length) return null;
    return this.bets.sort((a, b) =>
      a.bid_date > b.bid_date ? -1 : a.bid_date === b.bid_date ? 0 : 1,
    )[0];
  }

  get canEdit() {
    return this.bets.some((bid) => bid.canEdit);
  }
}

function parseJSON(visible_for: string): Array<{ id_customer_role: number }> {
  try {
    return JSON.parse(visible_for, (_, value) => +value);
  } catch {
    return visible_for as any;
  }
}
