import { useState, useEffect } from "react";
import CellarResellStatusSlideTable from "./CellarResellStatusSlideTable";
import { fetchDataInformationHandlingResell } from "../../../../fetch/fetchData";
import { useMyUser } from "src/app/hooks/useMyUser";

export function CellarResellStatusSlide({ participant }) {
  const [, setSlideActive] = useState("");

  useEffect(() => {
    setTimeout(function () {
      setSlideActive("active");
    }, 100);
  }, []);

  const user = useMyUser();

  useEffect(() => {
    fetchDataInformationHandlingResell(
      user,
      setDataInformationResell,
      setLoadDataInformationResell,
      participant.id_lot,
      user.id_user,
      participant.quantity_available,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (loadFetch && !loadDataInformationResell) {
        setLoadFetch(false);
      }
    }, 1000);
    return () => clearInterval(intervalId); //This is important
  });

  const [dataInformationResell, setDataInformationResell] = useState([]);
  const [loadDataInformationResell, setLoadDataInformationResell] =
    useState(true);

  const [dataAuctionFiltered] = useState([]);
  const [isFiltered] = useState(false);

  const [loadFetch, setLoadFetch] = useState(true);

  const refresh = () => {
    setLoadDataInformationResell(true);
    setLoadFetch(true);
    fetchDataInformationHandlingResell(
      user,
      setDataInformationResell,
      setLoadDataInformationResell,
      participant.id_lot,
      user.id_user,
      participant.quantity_available,
    );
  };

  return (
    <>
      <div className="cellar-shipments-slide-body">
        <div className="cru-table-wrap cru-table-physical cellar-shipments-table-wrap">
          <CellarResellStatusSlideTable
            data={dataInformationResell}
            isFiltered={isFiltered}
            dataFiltered={dataAuctionFiltered}
            refresh={refresh}
            loadFetch={loadFetch}
          />
        </div>
      </div>
    </>
  );
}
