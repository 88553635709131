import { Button } from "src/app/components/button";
import styles from "./more-products.module.css";
import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import { useAuctionsQuery } from "src/app/api/auctions";
import { ID_AUCTION_TYPE, PageName } from "src/app/const";
import { useState } from "react";
import { AuctionCardMd } from "src/app/components/auction-card/md";
import { NoBidsNew } from "src/app/modules/Includes/CustomerSide/components/common/NoBidsNew";
import { Grid } from "src/app/components/auction-card/lg/grid";
import SkeletonCollectionCard from "src/app/modules/Includes/CustomerSide/components/skeleton/SkeletonCollectionCard";
import { range } from "lodash";
import { AuctionCardLgNewToRefactor } from "src/app/components/auction-card/lg";

type Props = {
  auction: Auction;
};

const limit = 6;

export const MoreProducts = ({ auction }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const auctionsQuery = useAuctionsQuery(getAuctionsQueryConfig(auction));
  const auctions = (auctionsQuery.data || []).filter(
    (a) => a.id !== auction.id,
  );

  const showViewAllButton = auctions.length > limit && !showAll;

  if (!auctions.length) return null;

  return (
    <section>
      <h3 className={styles.title}>More products</h3>

      {auction.isForStore ? (
        <AuctionCardMd.Grid
          isLoading={auctionsQuery.isLoading}
          emptyFallback={<NoBidsNew title="Nothing found" />}
        >
          {auctions.slice(0, showAll ? undefined : limit).map((auction) => (
            <AuctionCardMd.Card
              key={`shop-auction-card-key-${auction.id_auction}-${auction.type_product}`}
              auction={auction}
              cardId={`auction-${auction.type_product}-${auction.id}`}
            />
          ))}
        </AuctionCardMd.Grid>
      ) : (
        <Grid isLoading={auctionsQuery.isLoading}>
          {(!!auctionsQuery.isLoading ||
            (!!auctionsQuery.isFetching && !auctions.length)) &&
            range(0, 4).map((i) => (
              <SkeletonCollectionCard key={`skel-card-arch-${i}`} />
            ))}

          {!auctionsQuery.isLoading &&
            !auctionsQuery.isFetching &&
            !auctions.length && <NoBidsNew title="Nothing found" />}

          {auctions.map((auction) => (
            <AuctionCardLgNewToRefactor
              key={`auction-xl-${auction.id_auction}-${auction.type_product}`}
              auction={auction}
              cardId={`auction-${auction.id_auction}`}
            />
          ))}
        </Grid>
      )}

      {!!showViewAllButton && (
        <div className={styles.viewAllRoot}>
          <Button
            onClick={() => setShowAll(true)}
            className={styles.viewAllButton}
            variant="outlined"
            label="View All"
          />
        </div>
      )}
    </section>
  );
};

function getAuctionsQueryConfig(auction: Auction) {
  const pageName: PageName = auction.isForStore
    ? "shop"
    : isAuctionOfBarrel(auction)
      ? "barrels"
      : auction.type.id === ID_AUCTION_TYPE.COLLECTION
        ? "collections"
        : "single-lots";

  const id_builder = isAuctionOfBarrel(auction)
    ? auction.barrel.builder.id
    : auction.lot?.components[0].builder.id;

  return { pageName, id_builder };
}
