import { useEvent } from "react-use";
import { useTypedSearchParams } from "./useTypedSearchParams";
import { z } from "zod";

type Config = {
  totalItems: number;
  step: number;
  scrollableEl: HTMLElement | null;
  isLoading: boolean;
};

export const useInfiniteScroll = ({
  totalItems,
  step,
  scrollableEl,
  isLoading,
}: Config) => {
  const scrollTarget = scrollableEl || document.body;
  const [searchParams, setSearchParams] = useTypedSearchParams({
    schema: z.object({ page: z.number().optional() }),
  });
  const page = searchParams.page || 1;
  const sliceEnd = page * step;

  useEvent(
    "scroll",
    () => {
      if (isLoading) return;
      const areAllItemsPainted = sliceEnd >= totalItems;
      const { scrollTop, scrollHeight, offsetHeight } = scrollTarget;
      const remainingPixelsToScroll = scrollHeight - (scrollTop + offsetHeight);

      if (remainingPixelsToScroll < 400 && !areAllItemsPainted) {
        setSearchParams({ page: page + 1 });
      }

      if (scrollTop < 600 && searchParams.page) {
        setSearchParams({ page: undefined });
      }
    },
    scrollTarget,
  );

  return { page, sliceEnd };
};
