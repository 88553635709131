import { useState, useRef } from "react";
import { UserProfileSidebar } from "../profile-sidebar";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AlertPopup } from "../../../modules/Includes/LayoutSide/components/common/alert-popup";
import { useUpdateMyUserMutation } from "src/app/api/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { isEqual } from "lodash";
import { useMyUser } from "src/app/hooks/useMyUser";
import { toImgSrc } from "src/app/utils/toImgSrc";
import { Checkbox } from "src/app/components/form-elements/checkbox";

export function MyProfile() {
  const user = useMyUser();
  const queryClient = useQueryClient();
  const [alertPopupMeta, setAlertPopupMeta] = useState(null);

  const updateMyUserMutation = useUpdateMyUserMutation({
    onSuccess() {
      queryClient.invalidateQueries();
      setAlertPopupMeta({
        title: "Your information has been changed",
        alertType: "success",
        onClose: () => window.location.reload(),
      });
    },
    onError() {
      queryClient.invalidateQueries();
      setAlertPopupMeta({
        title: "Error updating your information",
        alertType: "error",
        onClose: () => window.location.reload(),
      });
    },
  });

  const fileInput = useRef(null);

  const handleFileChange = (event) => {
    formik.setFieldValue("pic", event.target.files[0]);
  };

  // UI Helpers

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      pic: user.pic || "",
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      company_name: user.company_name !== "NULL" ? user.company_name || "" : "",
      birthday: !user.birthday ? "" : user.birthday,
      vat: user.addressInvoice[0]?.vat || "",
      email: user.email || "",
      password: user.currentPassword || "",
      id_user_profile: user.id_user || "",
      consent_marketing: user.consent_marketing,
      consent_profiling: user.consent_profiling,
    },
    validationSchema: Yup.object().shape({
      pic: Yup.string(),
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      id_user_profile: Yup.string(),
      email: Yup.string().email().required("Email is required"),
      password: Yup.string(),
    }),
    onSubmit: (values) => {
      updateMyUserMutation.mutate(values);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
  });

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <div className="user-profile-container">
        <UserProfileSidebar />
        <div className="upc-mainbody">
          <div className="account-info-form">
            <form
              name="edit-account-info"
              id="edit-account-info"
              action=""
              method="POST"
            >
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor=""></label>
                </div>
                <div className="upc-form-row-right">
                  <div
                    className="avatar-preview"
                    style={{
                      backgroundImage: `url(${toImgSrc(formik.values.pic)})`,
                    }}
                  >
                    <div
                      className="add-avatar-cta"
                      onClick={() => fileInput.current.click()}
                    >
                      Upload Picture
                    </div>
                  </div>
                  <input
                    type="file"
                    name="avatar-file"
                    id="avatar-file"
                    className="fileitem file-hidden"
                    value=""
                    ref={fileInput}
                    accept=".png, .jpg, .jpeg, .gif"
                    onChange={handleFileChange}
                  />
                  <div className="input-note">
                    Allowed file type: png, jpg, jpeg, gif
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="">First Name *</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="text"
                      placeholder="First name"
                      className={`inputbox ${getInputClasses("first_name")}`}
                      name="first_name"
                      {...formik.getFieldProps("first_name")}
                    />
                    {!!formik.touched.first_name &&
                      !!formik.errors.first_name && (
                        <div className="invalid-feedback">
                          {formik.errors.first_name}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="">Last Name *</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="text"
                      placeholder="Last name"
                      className={`inputbox ${getInputClasses("last_name")}`}
                      name="last_name"
                      {...formik.getFieldProps("last_name")}
                    />
                    {!!formik.touched.last_name &&
                      !!formik.errors.last_name && (
                        <div className="invalid-feedback">
                          {formik.errors.last_name}
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="">Company Name</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="text"
                      placeholder="Company name"
                      className={`inputbox`}
                      name="company_name"
                      {...formik.getFieldProps("company_name")}
                    />
                  </div>
                  <div className="input-note">
                    if you want your invoices addresses to a company. Leave
                    blank to use your full name
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="">Email Address *</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="text"
                      placeholder="Email"
                      className={`inputbox ${getInputClasses("email")}`}
                      name="email"
                      {...formik.getFieldProps("email")}
                    />
                    {!!formik.touched.email && !!formik.errors.email && (
                      <div className="invalid-feedback">
                        {formik.errors.email}
                      </div>
                    )}
                  </div>
                  <div className="input-note">
                    Email will not be publicly displayed
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left">
                  <label htmlFor="">Birth Day *</label>
                </div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <input
                      type="date"
                      placeholder="Birth day"
                      className={`inputbox`}
                      name="birthday"
                      {...formik.getFieldProps("birthday")}
                    />
                  </div>
                </div>
              </div>

              <div className="upc-form-row">
                <div className="upc-form-row-left"></div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <Checkbox
                        id="consent_profiling"
                        name="consent_profiling"
                        checked={formik.values.consent_profiling}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "consent_profiling",
                            e.target.checked,
                          );
                        }}
                      />
                      <label
                        htmlFor="consent_profiling"
                        style={{
                          fontSize: "0.8125rem",
                          marginTop: "-1px",
                        }}
                      >
                        Profiling: I acknowledge that Crurated utilizes
                        Artificial Intelligence (AI) technologies to enhance my
                        user experience, specifically corresponding and tailored
                        to my own activity on the platform.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="upc-form-row">
                <div className="upc-form-row-left"></div>
                <div className="upc-form-row-right">
                  <div className="inputwrap">
                    <div style={{ display: "flex", gap: "0.5rem" }}>
                      <Checkbox
                        id="consent_marketing"
                        name="consent_marketing"
                        checked={formik.values.consent_marketing}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "consent_marketing",
                            e.target.checked,
                          );
                        }}
                      />
                      <label
                        htmlFor="consent_marketing"
                        style={{
                          fontSize: "0.8125rem",
                          marginTop: "-1px",
                        }}
                      >
                        Marketing: I consent to being a recipient of updates,
                        tailored recommendations, marketing, events and
                        promotional content from Crurated.
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="upc-form-row upc-form-btn-row">
                <div className="upc-form-row-left">
                  <label>&nbsp;</label>
                </div>
                <div className="upc-form-row-right">
                  <button
                    onClick={formik.handleSubmit}
                    style={{ boxSizing: "border-box" }}
                    className={`btn btn-primary mb-3 mr-3 ${
                      updateMyUserMutation.isLoading ? `btn-processing` : ``
                    }`}
                    disabled={
                      updateMyUserMutation.isLoading ||
                      isEqual(formik.initialValues, formik.values) ||
                      !formik.isValid
                    }
                  >
                    Save Changes
                  </button>
                  <Link
                    to="/user-profile/profile-overview"
                    className="btn btn-secondary btn-outlined mb-3"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
