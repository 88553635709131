import moment from "moment";

export function TableAuctionStatus({ closed, status, finishData }) {
  var labelText = "Undefined";
  var labelClass = "undefined";

  if (closed) {
    labelText = "Closed";
    labelClass = "status-outstanding-payment";
  } else {
    if (parseInt(status) === 2) {
      labelText = "Finished";
      labelClass = "status-sell-crurated";
    } else {
      labelText = "Open";
      labelClass = "status-delivered";
    }
  }

  return (
    <>
      <div className="tps-content" style={{ cursor: "pointer" }}>
        <div className={`status-label ${labelClass}`}>{labelText}</div>

        {!closed && !!finishData && (
          <div className="offers-rdv-bottom-mob d-flex justify-content-center tpp-price-note">
            <div className="card-catalog-cta mt-0">
              <div className="card-info-date">
                <span className="cbd-icon-date-arrival"></span>
                <span className="cbd-text-2">
                  {moment(finishData).format("DD MMMM, YYYY")}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
