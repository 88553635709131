import {
  Fragment,
  ReactNode,
  useCallback,
  useId,
  useMemo,
  useState,
} from "react";

type NodeFn = (dismiss: () => void) => ReactNode;

export const useSlot = () => {
  const prefix = useId();

  const [nodesToRender, setNodesToRender] = useState<
    Array<{ node: ReactNode; key: string }>
  >([]);

  const dismissNode = useCallback((node: (typeof nodesToRender)[number]) => {
    setNodesToRender((nodes) => nodes.filter((n) => n.key !== node.key));
  }, []);

  const append = useCallback(
    (nodeFn: NodeFn) => {
      const key = `${prefix}-${Math.random()}`;
      const node = nodeFn(() => dismissNode({ key, node }));
      setNodesToRender((nodes) => [...nodes, { node, key }]);
    },
    [dismissNode, prefix],
  );

  const clear = useCallback(() => {
    setNodesToRender([]);
  }, []);

  const output = useMemo(
    () => (
      <Fragment>
        {nodesToRender.map(({ node, key }) => (
          <Fragment key={key}>{node}</Fragment>
        ))}
      </Fragment>
    ),
    [nodesToRender],
  );

  return {
    /**
     * The JSX output
     *
     * @example
     * <div>{output}</div>
     * <Portal>{output}</Portal>
     */
    output,
    /**
     * Imperativaly add a node to render in the output \
     * Receives a dismiss function as an arg to dismiss just this node.
     *
     * @example
     * // This button will be dismissed when clicked
     * slot.append(dismiss => (
     *  <button onClick={dismiss}>
     *    Click Me
     *  </button>
     * ))
     */
    append,
    /**
     * An alias for "append"
     */
    show: append,
    /**
     * Removes all node from the output
     */
    clear,
  };
};
