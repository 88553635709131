import { ReactNode } from "react";
import { ID_IO_ROOT } from "src/app/const";
import { useInfiniteScroll } from "src/app/hooks/useInfiniteScroll";

type Props = {
  children: ReactNode[];
  isLoading: boolean;
};

export const Grid = ({ children, isLoading }: Props) => {
  const cleanChildren = children.filter(Boolean).flat();

  const infiniteScroll = useInfiniteScroll({
    totalItems: cleanChildren.length,
    scrollableEl: document.getElementById(ID_IO_ROOT),
    step: 6,
    isLoading,
  });

  return (
    <div
      className="lots-collection-cards-container"
      style={{ paddingBottom: "24px" }}
    >
      {cleanChildren.slice(0, infiniteScroll.sliceEnd)}
    </div>
  );
};
