import { ButtonToggleEdit } from "./components/button-toggle-edit";
import { ButtonWish } from "./components/button-wish";
import { Select } from "./components/select";
import { useIsAuctionFinished } from "src/app/hooks/useIsAuctionFinished";
import { Form } from "./components/form";
import { BidStatus } from "./components/bid-status";
import { ButtonSubmit } from "./components/button-submit";
import { ButtonArchive } from "./components/button-archive";
import { isAuctionOfBarrel } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { CreditCardLabel } from "./components/credit-card-label";
import { useMyUser } from "src/app/hooks/useMyUser";
import AddInfoAndBuyDialog from "../../atoms/add-info-and-buy-dialog";
import { useState } from "react";
import InfoMessage from "./components/info-message";
import { PiWarningDuotone } from "react-icons/pi";
import { useMedia } from "react-use";

export const ForCollection = () => {
  const isWide = useMedia("(min-width: 1044px)");
  const cardCtx = useCardCtx();
  const isFinished = useIsAuctionFinished(cardCtx.auction);

  const showBidStatus =
    (isFinished || !cardCtx.auction.canBid) && cardCtx.auction.myLastBid;
  const showToggleEditButton = !isFinished && cardCtx.auction.canEdit;
  const showArchiveAndWish = !isFinished && !cardCtx.auction.myLastBid;
  const shouldNotBeInWishlist = !!cardCtx.auction.myLastBid && cardCtx.isWished;

  const user = useMyUser();
  const hasCard = !user.checkUser.is_missing_card;
  const [showAddressAndCardModal, setShowAddressAndCardModal] = useState(false);

  return (
    <>
      {!!showAddressAndCardModal && (
        <AddInfoAndBuyDialog
          onClose={() => setShowAddressAndCardModal(false)}
          onBuy={() => {
            setShowAddressAndCardModal(false);
            cardCtx.handleSubmit();
          }}
        />
      )}
      <Form
        auction={cardCtx.auction}
        empLabel="Estimated Market Price"
        empTooltipContent="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles."
        totalLabel="Excl. of processing fees and taxes"
        totalTooltipContent="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
        price={cardCtx.price}
        quantity={cardCtx.quantity}
        inputLeft={
          <Select
            label="Quantity"
            value={cardCtx.quantity}
            options={cardCtx.auction.quantityOptions}
            onChange={cardCtx.setQuantity}
            disabled={
              isFinished || (!cardCtx.auction.canBid && !cardCtx.isEditing)
            }
          />
        }
        inputRight={
          <Select
            label={
              isAuctionOfBarrel(cardCtx.auction)
                ? `Offer per ${cardCtx.auction.barrel!.nft_liters} L`
                : cardCtx.auction.hasExperience
                  ? "Offer per Pass"
                  : "Offer per Lot"
            }
            value={cardCtx.price}
            options={cardCtx.auction.priceOptions}
            onChange={cardCtx.setPrice}
            disabled={
              isFinished || (!cardCtx.auction.canBid && !cardCtx.isEditing)
            }
          />
        }
        actionLeft={
          showToggleEditButton ? (
            <ButtonToggleEdit
              isEditing={cardCtx.isEditing}
              onClick={() => {
                // reset the values when cancelling
                cardCtx.toggleIsEditing();
                cardCtx.setQuantity(cardCtx.auction.initialQuantity);
                cardCtx.setPrice(cardCtx.auction.initialPrice);
              }}
            />
          ) : showArchiveAndWish ? (
            <ButtonArchive
              isArchived={cardCtx.auction.isArchive}
              isLoading={cardCtx.isArchiveLoading}
              onClick={cardCtx.toggleArchive}
            />
          ) : null
        }
        actionCenter={
          cardCtx.isEditing ? (
            <ButtonSubmit
              label="Edit Offer"
              forbidden={false}
              isLoading={cardCtx.isEditLoading}
              onClick={cardCtx.editBid}
            />
          ) : showBidStatus && cardCtx.auction.myLastBid ? (
            <BidStatus status={cardCtx.auction.myLastBid.bid_status.label} />
          ) : (
            <ButtonSubmit
              label="Submit an Offer"
              forbidden={
                !!(!cardCtx.auction.canBid || isFinished) &&
                !!user.addressInvoice.length &&
                !!hasCard
              }
              isLoading={cardCtx.isMakeABidLoading}
              onClick={() =>
                (!user.addressInvoice.length || !hasCard) &&
                user.id_customer_role !== 14
                  ? setShowAddressAndCardModal(true)
                  : cardCtx.handleSubmit()
              }
            />
          )
        }
        actionRight={
          (!!showArchiveAndWish || !!shouldNotBeInWishlist) && (
            <ButtonWish
              isWished={cardCtx.isWished}
              onClick={cardCtx.toggleIsWished}
              isLoading={cardCtx.isWishLoading}
            />
          )
        }
        infoMessage={
          !!cardCtx.auction.whyCantBid && (
            <InfoMessage
              isWide={isWide}
              title={cardCtx.auction.whyCantBid}
              icon={
                <PiWarningDuotone
                  style={{ display: "block", fontSize: 16 }}
                  color="#C87A06"
                />
              }
            />
          )
        }
        creditCardLabel={<CreditCardLabel />}
      />
    </>
  );
};
