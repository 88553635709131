import { z } from "zod";
import { ID_TIER, PLAN_RECURRENCE } from "../const";

const apiMySubscriptionsV1Base = z.object({
  active: z.boolean(),
  class: z.number(),
  coupon_code: z.any(),
  created_date: z.string(),
  date_end_subscription: z.string(),
  date_end_trial: z.string(),
  date_on_end_trial: z.string(),
  date_start_trial: z.string(),
  date_subscription: z.string(),
  deleted: z.union([z.literal(0), z.literal(1)]),
  description: z.string(),
  full_price: z.number(),
  id_customer_role: z.nativeEnum(ID_TIER),
  id_customer_role_now: z.number(),
  id_customer_role_old: z.number(),
  id_price_stripe: z.string(),
  id_subscription: z.number(),
  id_subscription_handling: z.number(),
  id_subscription_stripe: z.string(),
  id_type_subscription: z.number(),
  id_user: z.number(),
  initial_fee: z.number(),
  is_expired: z.boolean(),
  is_trial: z.union([z.literal(0), z.literal(1)]),
  name: z.string(),
  price: z.number(),
  processed: z.number(),
  recurring_type: z.nativeEnum(PLAN_RECURRENCE),
  sorting: z.number(),
  status: z.number().min(0).max(10),
});

export const apiMySubscriptionsV1Schema = apiMySubscriptionsV1Base.merge(
  z.object({ story_subscription: z.array(apiMySubscriptionsV1Base) }),
);

export type MySubscriptionsV1Base = z.infer<typeof apiMySubscriptionsV1Base>;
export type MySubscriptionsV1 = z.infer<typeof apiMySubscriptionsV1Schema>;
