import { CSSProperties } from "react";
import { BannerCarousel } from "./banner-carousel";
import { BannerSlide } from "./banner-slide";
import augustMobile from "./Mobile-Banner-Full-min.jpg";
import augustDesktop from "./Desktop-Banner-Full-min.jpg";

type Props = {
  style?: CSSProperties;
};

export function Banner({ style }: Props) {
  return (
    <BannerCarousel
      style={style}
      slides={[
        <BannerSlide
          key="banner-slide-1"
          srcDesktop={augustDesktop}
          srcMobile={augustMobile}
          alt="August Sale"
          // the banner will be removed on this date
          endDateExcluded="09-01-2024"
          /**
           * The markup takes the whole size of the slide.
           * Put eventual links here
           */
          // markup={<A to="https://a.b.c">Summer Sales 15% off</A>}
        />,
      ]}
    />
  );
}
