import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import { QKEY } from "./QKEY";
import { upfetch, upfetchV1 } from "./upfetch";
import { useMyUser } from "../hooks/useMyUser";
import { z } from "zod";
import { apiShipmentSchemaV1, ShipmentV1 } from "../models/ShipmentV1";
import { ShipmentFormValues } from "../components/shipment-form/shipment-form";
import { useSystemConfig } from "../hooks/useSystemConfig";
import { AddressV1 } from "../models/Address";
import { Lot } from "../models/Lot";

/**
 * QUERIES
 */

const getShipments = {
  queryKey: () => [QKEY.SHIPMENT],
  queryFn: (token: string, userId: number) => async () => {
    return upfetchV1({
      url: "shippings/GetAuctionShippingNew/",
      params: {
        accessToken: token,
        id_user: userId,
      },
      schema: z.array(apiShipmentSchemaV1),
    }).then((apiShipments) => apiShipments.map((s) => new ShipmentV1(s)));
  },
};

export const useShipmentsQuery = (
  options: UseQueryOptions<ShipmentV1[]> = {},
) => {
  const { accessToken, id_user } = useMyUser();

  return useQuery({
    queryKey: getShipments.queryKey(),
    queryFn: getShipments.queryFn(accessToken, id_user),
    ...options,
  });
};

export const useRequestShipmentMutation = (
  options: UseMutationOptions<any, any, ShipmentFormValues> = {},
) => {
  const user = useMyUser();
  const config = useSystemConfig();

  return useMutation({
    ...options,
    mutationFn: (values) => {
      if (!values.selectedAddress) throw new Error("Missing selected Address");
      return upfetchV1({
        url: `shippings/RequestShipping/`,
        method: "POST",
        body: {
          accessToken: user.accessToken,
          id_address: values.selectedAddress.id_address,
          id_user: user.id,
          lots: values.lots
            .filter((lot) => !!lot.quantity_chosen)
            .map((lot) => ({
              id_lot: lot.id_lot,
              quantity_chosen: lot.quantity_chosen,
            })),
          pickup_address: Number(
            values.selectedAddress.id_address ===
              config?.pickup_address.id_address,
          ),
        },
      });
    },
  });
};

//TODO: v2 migration, types
export const useDeleteShipmentMutation = (
  options: UseMutationOptions<any, any, any> = {},
) => {
  const user = useMyUser();

  return useMutation({
    ...options,
    mutationFn: (shipping) => {
      return upfetchV1({
        url: `shippings/DeleteRequestShipping/`,
        method: "POST",
        body: {
          accessToken: user.accessToken,
          shipping_auctions_participant: shipping.shipping_auctions_participant,
          id_shipping: shipping.id_shipping,
        },
        parseResponse: async (res) => await res.json(),
      }).then((res) => {
        if (res.code !== 200) throw new Error(res.error);
      });
    },
  });
};

//TODO: v2 migration, types
export const usePayShipmentMutation = (
  options: UseMutationOptions<any, any, any> = {},
) => {
  const user = useMyUser();

  return useMutation({
    ...options,
    mutationFn: (shipping) => {
      return upfetchV1({
        url: `shippings/PaymentShipment/`,
        method: "POST",
        body: {
          accessToken: user.accessToken,
          shipping: shipping,
          id_shipping: shipping.id_shipping,
        },
        parseResponse: async (res) => await res.json(),
      }).then((res) => {
        if (res.code !== 200) throw new Error(res.error);
      });
    },
  });
};

type UpdateShipmentPayload = {
  id_address: number;
  pickup_address: boolean;
};

export const useUpdateShipmentMutation = (
  id: number,
  options: UseMutationOptions<any, any, UpdateShipmentPayload> = {},
) => {
  return useMutation({
    mutationFn({ id_address, pickup_address }) {
      return upfetch({
        url: `shipping/${id}/edit/`,
        method: "POST",
        body: pickup_address ? { pickup_address } : { id_address },
      });
    },
    ...options,
  });
};

export const useCombineShipmentsMutation = (
  options: UseMutationOptions<any, any, any> = {},
) => {
  const user = useMyUser();

  return useMutation({
    ...options,
    mutationFn: async ({
      selectedAddress,
      selectedShippings,
      selectedLots,
    }: {
      selectedAddress: AddressV1;
      selectedShippings: ShipmentV1[];
      selectedLots: Lot[];
    }) => {
      const res = await upfetchV1({
        url: `shippings/CombineShippings/`,
        method: "POST",
        body: {
          shippings: selectedShippings,
          addressSelected: selectedAddress,
          lots: selectedLots,
          id_user: user.id_user,
          id_address: selectedAddress.id_address,
          pickup_address: 0,
          accessToken: user.accessToken,
        },
        parseResponse: async (res) => await res.json(),
      });

      if (res.code !== 200) {
        throw new Error(res.error);
      }
    },
  });
};
