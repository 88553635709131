import { CheckboxWithLabel } from "../../../components/form-elements/checkbox/checkbox-with-label";
import { SignupFormValues } from ".";
import { z } from "zod";
import { Button } from "src/app/components/button";
import { TierName } from "src/app/const";
import { Label } from "../components/label";
import { CruForm } from "src/app/components/cru-form";
import { Input } from "src/app/components/form-elements/input";
import { LoginLink } from "../components/login-link";
import { getMaxBirthDate, getMinBirthDate } from "../utils";
import { Headline } from "../components/headline";
import { SignupStepper } from "../components/signup-stepper";
import { InputPassword } from "src/app/components/form-elements/input-password";
import { useCheckEmailQuery } from "src/app/api/subscription";
import { CruFormContextType } from "src/app/components/cru-form/types";
import { validate } from "src/app/utils/validate";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";

type Props = {
  tier: TierName;
  cruForm: CruFormContextType<SignupFormValues>;
};

export const Step1 = ({ cruForm, tier }: Props) => {
  const checkEmailQuery = useCheckEmailQuery(cruForm.values.email, {
    enabled: cruForm.isInitialized && !cruForm.errors.email,
  });

  return (
    <>
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          marginBottom: 20,
        }}
      >
        <SignupStepper description="Personal Details" step={2} />
        <Headline>
          Please enter your personal details to begin your registration
        </Headline>
      </header>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
          marginBottom: 30,
        }}
      >
        <div>
          <Label required htmlFor="input-firstName">
            First Name
          </Label>

          <CruForm.Field
            id="input-firstName"
            name="firstName"
            style={{ height: 50 }}
            component={Input}
            type="text"
            placeholder="John"
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ firstName: e.target.value })}
            onBlur={() => cruForm.setTouched({ firstName: true })}
            value={cruForm.values.firstName}
            validate={(value) => {
              const validation = z
                .string({
                  invalid_type_error: "First name must be a string",
                })

                .min(1, "First name is required")
                .min(2, "Should have minimum 2 characters")
                .max(50, "Should have maximum 50 characters")
                .safeParse(value);
              if (/\d/.test(value)) {
                return "The name cannot contain numbers";
              }
              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="firstName" />
        </div>

        <div>
          <Label required htmlFor="input-lastName">
            Last Name
          </Label>
          <CruForm.Field
            id="input-lastName"
            name="lastName"
            style={{ height: 50 }}
            component={Input}
            type="text"
            placeholder="Doe"
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ lastName: e.target.value })}
            onBlur={() => cruForm.setTouched({ lastName: true })}
            value={cruForm.values.lastName}
            validate={(value) => {
              const validation = z
                .string({
                  invalid_type_error: "Last name must be a string",
                })
                .min(1, "Last name is required")
                .min(2, "Should have minimum 2 characters")
                .max(50, "Should have maximum 50 characters")
                .safeParse(value);
              if (/\d/.test(value)) {
                return "The last name cannot contain numbers";
              }
              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="lastName" />
        </div>

        <div>
          <Label required htmlFor="input-birthday">
            Date of Birth
          </Label>
          <CruForm.Field
            id="input-birthday"
            name="birthday"
            min={getMinBirthDate()}
            max={getMaxBirthDate()}
            style={{ height: 50 }}
            component={Input}
            type="date"
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ birthday: e.target.value })}
            onBlur={() => cruForm.setTouched({ birthday: true })}
            value={cruForm.values.birthday}
            validate={(value) => {
              const validation = z
                .string()
                .min(1, "Date of Birth is required")
                .refine(
                  (birthday) => birthday < getMaxBirthDate(),
                  "You must be at least 18 years old",
                )
                .refine(
                  (birthday) => birthday > getMinBirthDate(),
                  "Please insert a valid birthdate",
                )
                .safeParse(value);

              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="birthday" />
        </div>

        <div>
          <Label required htmlFor="input-email">
            Email
          </Label>
          <CruForm.Field
            id="input-email"
            name="email"
            style={{ height: 50 }}
            component={Input}
            type="email"
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ email: e.target.value })}
            onBlur={() => cruForm.setTouched({ email: true })}
            value={cruForm.values.email}
            placeholder="john.doe@example.com"
            validate={(value) => {
              const validation = z
                .string()
                .min(1, "Email is required")
                .email("Invalid email address")
                .safeParse(value);

              if (!validation.success) {
                return validation.error.issues[0].message;
              }
            }}
          />

          {checkEmailQuery.data?.already_used ? (
            <div style={{ color: "red", fontSize: "0.75rem" }}>
              Email already used
            </div>
          ) : (
            <CruForm.ErrorMessage cruForm={cruForm} name="email" />
          )}
        </div>

        <div>
          <Label required htmlFor="input-password">
            Password
          </Label>
          <CruForm.Field
            id="input-password"
            name="password"
            style={{ height: 50 }}
            component={InputPassword}
            cruForm={cruForm}
            onChange={(e) => cruForm.setValues({ password: e.target.value })}
            onBlur={() => cruForm.setTouched({ password: true })}
            value={cruForm.values.password}
            validate={validate.password}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="password" />
        </div>

        <div>
          <Label required htmlFor="input-confirmPassword">
            Confirm Password
          </Label>
          <CruForm.Field
            id="input-confirmPassword"
            name={"confirmPassword"}
            style={{ height: 50 }}
            component={InputPassword}
            cruForm={cruForm}
            onChange={(e) =>
              cruForm.setValues({ confirmPassword: e.target.value })
            }
            onBlur={() => cruForm.setTouched({ confirmPassword: true })}
            value={cruForm.values.confirmPassword}
            validate={(value, values) => {
              if (!value) return "Write your password again";
              if (value !== values.password) return "Passwords must match";
            }}
          />
          <CruForm.ErrorMessage cruForm={cruForm} name="confirmPassword" />
        </div>
      </section>

      <section
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          marginBottom: 40,
        }}
      >
        <CruForm.Field
          required
          name="termsAccepted"
          component={CheckboxWithLabel}
          cruForm={cruForm}
          onChange={(e) => {
            cruForm.setValues({ termsAccepted: e.target.checked });
            cruForm.setTouched({ termsAccepted: true });
          }}
          checked={cruForm.values.termsAccepted}
          validate={(termsAccepted) =>
            !termsAccepted && "You must accept Terms & Conditions"
          }
          label={<A to={path.termsAndConditions()}>Terms and Conditions</A>}
          description="I authorize Crurated to collect, store, and process my personal data for the purposes of delivering and enhancing their services."
          renderError={
            <CruForm.ErrorMessage cruForm={cruForm} name="termsAccepted" />
          }
        />

        {/* <CruForm.Field
          name="profiling"
          component={CheckboxWithLabel}
          cruForm={cruForm}
          onChange={(e) => {
            cruForm.setValues({ profiling: e.target.checked });
            cruForm.setTouched({ profiling: true });
          }}
          checked={cruForm.values.profiling}
          label="Profiling"
          description="I acknowledge that Crurated utilizes Artificial Intelligence (AI)
          technologies to enhance my user experience, specifically
          corresponding and tailored to my own activity on the platform."
          renderError={
            <CruForm.ErrorMessage cruForm={cruForm} name="profiling" />
          }
        /> */}

        <CruForm.Field
          name="marketing"
          component={CheckboxWithLabel}
          cruForm={cruForm}
          onChange={(e) => {
            cruForm.setValues({ marketing: e.target.checked });
            cruForm.setTouched({ marketing: true });
          }}
          checked={cruForm.values.marketing}
          label="Marketing"
          description="I want to receive exclusive updates about incredible wines, personalized offers, and invitations to closed community events from Crurated."
          renderError={
            <CruForm.ErrorMessage cruForm={cruForm} name="marketing" />
          }
        />
      </section>

      <Button
        label={tier === "explorer" ? "Sign Up" : "Next"}
        isLoading={cruForm.submission.isLoading}
        disabled={!cruForm.hasErrors && checkEmailQuery.data?.already_used}
        style={{ width: "100%" }}
      />

      <LoginLink />
    </>
  );
};
