import { z } from "zod";
import { ID_TIER, PLAN_RECURRENCE } from "../const";

export const apiSubscriptionV1Schema = z.object({
  ac_tag: z.number(),
  active: z.union([z.literal(0), z.literal(1)]),
  created_date: z.string(),
  dateFrontendComplete: z.string(),
  date_on_end_trial: z.string(),
  deleted: z.union([z.literal(0), z.literal(1)]),
  description: z.string().nullable(),
  edited_date: z.string().nullable(),
  extra_storage_annual_cost: z.number().nullable(),
  full_price: z.number(),
  have_subscription: z.union([z.literal(0), z.literal(1)]),
  hidden_frontend: z.union([z.literal(0), z.literal(1)]),
  hidden_registration: z.union([z.literal(0), z.literal(1)]),
  id_customer_role: z.nativeEnum(ID_TIER),
  id_price_stripe: z.string(),
  id_subscription: z.number(),
  initial_fee: z.number(),
  insurance_processing_fee_barrels: z.string(),
  insurance_processing_fee_lots: z.string(),
  is_trial: z.union([z.literal(0), z.literal(1)]),
  max_free_storage_bottles: z.number().nullable(),
  maxi_cases_barrels: z.string(),
  maxi_cases_lots: z.string(),
  name: z.string(),
  recurring_type: z.nativeEnum(PLAN_RECURRENCE),
  require_confirm: z.union([z.literal(0), z.literal(1)]),
  sorting: z.number(),
});

export type SubscriptionV1 = z.infer<typeof apiSubscriptionV1Schema>;
