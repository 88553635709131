import { noop } from "lodash";
import { ReactComponent as IconPen } from "src/media/pen.svg";
import { ReactComponent as IconCross } from "src/media/cross.svg";
import { useIsMutating } from "@tanstack/react-query";
import { Button } from "src/app/components/button";

type Props = {
  isEditing: boolean;
  onClick: () => void;
};

export const ButtonToggleEdit = ({ isEditing, onClick = noop }: Props) => {
  const isMutating = !!useIsMutating();

  return (
    <Button
      variant="outlined"
      size={50}
      onClick={() => onClick()}
      tooltip={isEditing ? "Cancel changes" : "Edit your Offer"}
      icon={isEditing ? <IconCross /> : <IconPen />}
      disabled={isMutating}
    />
  );
};
