import useResizeObserver from "use-resize-observer";
import { Portal } from "@radix-ui/react-portal";
import { MutableRefObject, ReactNode } from "react";
import { useWindowSize } from "react-use";
import { toPx } from "src/app/utils/toPx";

const body = document.querySelector("body")!;

type Props = {
  anchorRef: MutableRefObject<HTMLElement | null>;
  children: ReactNode;
  withPortal: boolean | string;
  placement: "top" | "bottom";
};

export const DropdownRoot = ({
  anchorRef,
  children,
  withPortal,
  placement,
}: Props) => {
  // rerender when the window gets resized
  useWindowSize();
  // rerender on layout shift
  const { ref } = useResizeObserver();
  // rerender when the anchor gets resized
  useResizeObserver({ ref: anchorRef });

  if (!withPortal) {
    return (
      <div
        ref={ref}
        style={{
          position: "absolute",
          width: toPx(anchorRef.current?.offsetWidth),
          top:
            placement === "bottom"
              ? toPx(
                  (anchorRef.current?.offsetHeight || 0) +
                    (anchorRef.current?.offsetTop || 0),
                )
              : undefined,
          bottom:
            placement === "top"
              ? toPx(
                  (anchorRef.current?.offsetHeight || 0) +
                    (anchorRef.current?.offsetTop || 0),
                )
              : undefined,
          left: anchorRef.current?.offsetLeft,
          zIndex: 12,
        }}
      >
        {children}
      </div>
    );
  }

  const container: HTMLElement =
    typeof withPortal === "string"
      ? document.querySelector(withPortal) || body
      : body;

  return (
    <Portal
      ref={ref}
      container={container}
      style={{
        position: "absolute",
        top:
          placement === "bottom"
            ? getPortalPosition(anchorRef.current, container)?.top
            : undefined,
        bottom: getPortalPosition(anchorRef.current, container)?.bottom ?? 0,

        left: getPortalPosition(anchorRef.current, container)?.left,
        width: toPx(anchorRef.current?.offsetWidth),
        zIndex: 1212,
      }}
    >
      {children}
    </Portal>
  );
};

function getPortalPosition(el: HTMLElement | null, container: HTMLElement) {
  if (!el) return null;
  const containerBounds = container.getBoundingClientRect();
  const elBounds = el.getBoundingClientRect();

  return {
    top: elBounds.top - containerBounds.top + elBounds.height,
    bottom: containerBounds.bottom - elBounds.bottom + elBounds.height,
    left: elBounds.left - containerBounds.left,
  };
}
